import React, { useState } from "react";
import Options from '../formbuilder/Options'
import Field from '../formbuilder/Field'
import CheckBox from "./CheckBox";
import { faWindowClose, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PreviewFieldComponent from "../../../components/PreviewFieldComponent";



const PreviewForm = ({ questions, handleClose }: { questions: any[], handleClose: any }) => {


    return (

        <form className="preview-form" >
            <div className="grid-wrapper">
                <div className="grid-header">
                        <div className="close-icon">
                            <button className="border-0 background-transparent">
                                 <FontAwesomeIcon title='close' cursor='hand' onClick={()=>{
                               handleClose()
                            }} icon={faTimes} />
                            </button>
                           

                        </div>

                    
                    <div className="row">

                        <div className="col">
                            Question Title
                        </div>

                        <div className="col">
                            Answers
                        </div>

                    </div>
                </div>
                {questions.map((item: { id: string, question: string, answerType: string }) => {
                    return (
                        <div className="row align-items-center row-height mtb10-mlrauto">
                            <div className="col">
                                {item.question}
                            </div>
                            <div className="col preview-answers">
                                <PreviewFieldComponent question={item}></PreviewFieldComponent>
                            </div>

                        </div>
                    )
                })}
            </div>

        </form>
    );
};

export default PreviewForm