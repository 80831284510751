import React from "react";

const Field = (
    { field, type }: { field: any, type: any }

) => {
    return (

        type === 'textbox' ?
            <div key={field._uid}>

                <input
                    type={type || field.component}
                    id={field._uid}
                    name={field._uid}
                />
            </div>

            :
            <div key={field._uid}>

                <textarea></textarea>

            </div>
    );
};

export default Field;