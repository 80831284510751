import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import { FirestoreClient } from "../../library/FirestoreClient";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Loader from "../../components/Loader";
import { TableNames } from "../../helper/TableNames";
import { IAmenities } from "../../models/ClinicType";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import Table from "../../components/Table";
import { useErrorHandling } from '../../ErrorBoundary';




const ClinicAmenitiesSchema = Yup.object().shape({
    inventoryNumber: Yup.number()
        .typeError('Inventory number is invalid')
        .min(0, 'Min value 0.')
        .required("Inventory number is required"),
    noOfBeds: Yup.number()
        .typeError('No. of beds is invalid')
        .min(0, 'Min value 0.')
        .required("No. of beds is required"),
    customs: Yup.array()
        .of(
            Yup.object().shape({
                label: Yup.string().required("Custom Label is required"),
                content: Yup.string().required("Content is required"),
            })
        )
        .required("Required"),
});

const ClinicAmenities = () => {

    const emailId = sessionStorage.getItem('email')

    const [loading, setLoading] = useState(false);


    const [amnetiesObj, setAmnetiesObj] = useState({

        inventoryNumber: '',
        noOfBeds: '',
        customs: []

    })
    const navigate = useNavigate()

    const { triggerError } = useErrorHandling();


    const successCallback = (response: any) => {
        toast.success('Created Amenities successfully')
        navigate('/clinic/amenities')
    }
    let amenitiesInfo: any = []
    let ClinicAmenities = {

        inventoryNumber: '',
        noOfBeds: '',
        customs: []

    }
    useFirestoreConnect({
        collection: TableNames.Clinics,
        storeAs: "Clinic",
        where: ['clinicEmail', '==', emailId],
    });

    console.log(emailId)

    let clinic: { id: any; };

    let clinicObj = useSelector((state: any) => state.firestore.data.Clinic);

    if (isLoaded(clinicObj)) {
        try {
            amenitiesInfo = Object.values(clinicObj).filter((item:any)=>item.clinicEmail===emailId);
            clinic=amenitiesInfo[0]
            ClinicAmenities = amenitiesInfo[0].ClinicAmenities


        } catch (error) {

        }

    }



    if (!isLoaded(clinicObj)) {
        return (
            <Loader />
        )
    } else {


        return (
            <>
                <Formik
                    initialValues={{
                        inventoryNumber: ClinicAmenities?.inventoryNumber || '',
                        noOfBeds: ClinicAmenities?.noOfBeds || '',
                        customs: ClinicAmenities?.customs || [
                            {
                                label: "",
                                content: ""
                            }
                        ],
                    }}
                    validationSchema={ClinicAmenitiesSchema}
                    onSubmit={(values, actions) => {

                        const dataObject: IAmenities = new class implements IAmenities {
                            inventoryNumber = values.inventoryNumber
                            noOfBeds = values.noOfBeds
                            customs = values.customs
                        }

                        const objectJson = JSON.parse(JSON.stringify(dataObject));
                        console.log( clinicObj.id)
                        FirestoreClient.update(TableNames.Clinics, { ClinicAmenities: objectJson }, clinic.id).then((response) => {
                            successCallback(response)
                            actions.setSubmitting(false)

                        }).catch((error) => {
                           // triggerError(error)

                           console.log(error)
                            FirestoreClient.errorCallback(error)
                            actions.setSubmitting(false)

                        })
                    }}
                    render={({ errors, touched, values, isSubmitting }) => (
                        <Form>
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Amenities</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>Inventory Number</label>
                                                <Field
                                                    type="text"
                                                    name="inventoryNumber"
                                                    maxLength={6}
                                                    className={'form-control' + (errors.inventoryNumber && touched.inventoryNumber ? ' is-invalid' : '')}
                                                />
                                                <ErrorMessage name="inventoryNumber" component="div"
                                                    className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label>No. of Beds</label>
                                                <Field
                                                    type="text"
                                                    name="noOfBeds"
                                                    maxLength={6}
                                                    className={'form-control' + (errors.noOfBeds && touched.noOfBeds ? ' is-invalid' : '')}
                                                />
                                                <ErrorMessage name="noOfBeds" component="div"
                                                    className="invalid-feedback" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="add-more-wrapper">
                                        <FieldArray name="customs">
                                            {({ insert, remove, push }) => (
                                                <>
                                                    <div className="row mb-2">
                                                        <div className="col-5">Custom Label</div>
                                                        <div className="col-5">Content</div>
                                                        <div className="col-2">
                                                        </div>
                                                    </div>
                                                    {values.customs.length > 0 && values.customs.map((custom: any, index: any) => {
                                                        return (
                                                            <>
                                                                <div className="row" key={index}>
                                                                    <div className="col-5">
                                                                        <div className="form-group form-group-array">
                                                                            <ErrorMessage
                                                                                name={`customs.${index}.label`}
                                                                                component="div"
                                                                                className="invalid-feedback-array"
                                                                            />
                                                                            <Field
                                                                                name={`customs.${index}.label`}
                                                                                placeholder="Enter Custom label"
                                                                                type="text"
                                                                                className={'form-control'}
                                                                            />
                                                                            <ErrorMessage
                                                                                name={`customs.${index}.label`}
                                                                                component="div"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        <div className="form-group form-group-array">
                                                                            <ErrorMessage
                                                                                name={`customs.${index}.content`}
                                                                                component="div"
                                                                                className="invalid-feedback-array"
                                                                            />
                                                                            <Field
                                                                                name={`customs.${index}.content`}
                                                                                placeholder="Enter content"
                                                                                type="text"
                                                                                // className="form-control"
                                                                                className={'form-control'}
                                                                            />
                                                                            <ErrorMessage
                                                                                name={`customs.${index}.content`}
                                                                                component="div"
                                                                                className="invalid-feedback"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        {values.customs.length !== 1 && <button
                                                                            type="button"
                                                                            onClick={() => remove(index)}
                                                                            className="btn btn-danger"
                                                                        >
                                                                            <FontAwesomeIcon icon={faTrash} size="xs" />
                                                                            <span style={{
                                                                                marginLeft: '5px',
                                                                                fontSize: '13px'
                                                                            }}>Remove</span>
                                                                        </button>}
                                                                        {values.customs.length - 1 === index && <button
                                                                            type="button"
                                                                            className="btn btn-success"
                                                                            onClick={() => push({
                                                                                label: "",
                                                                                content: ""
                                                                            })}
                                                                        >
                                                                            <FontAwesomeIcon icon={faPlus} size="xs" />
                                                                            <span style={{
                                                                                marginLeft: '5px',
                                                                                fontSize: '13px'
                                                                            }}>Add</span>
                                                                        </button>}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </FieldArray>
                                    </div>
                                </div>
                                <div className="card-footer text-center">
                                    <button
                                        name="submit"
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={isSubmitting}>
                                        {isSubmitting && (
                                            <FontAwesomeIcon className="fa-spin me-2" icon={faSpinner} />
                                        )}
                                        Submit
                                    </button>
                                    <Link to="/clinic/dashboard" className="btn btn-danger">Cancel</Link>
                                </div>
                            </div>
                        </Form>
                    )}
                />
            </>
        );
    }
};
export default ClinicAmenities;
