import * as React from 'react';
import {Link, useNavigate} from "react-router-dom";
import Logo from '../assets/images/logo-icon.svg';
import {useLogin} from "../hooks/login.hook";

import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';

import {useCommon} from "../hooks/common.hooks";
import {useClinic} from "../hooks/clinic.hook"
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";

const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
});


const ForgotPassword = () => {
    const navigate = useNavigate();
    const {forgotPassword} = useLogin()

    const successCallBack = (data: any) => {
        toast.success('Reset password link has been to your email.')
        setTimeout(()=> {
            navigate("/");
        }, 3000)
    }

    return (

        <div className="login-wrapper">
            <div className="loginbox">
                <div className="login-left">
                    <img className="img-fluid" src={Logo} alt="Logo"/>
                </div>
                <div className="login-right">
                    <div className="login-right-wrap">
                        <h1>Forgot Password</h1>
                        <Formik
                            initialValues={{
                                email: '',
                            }}
                            validationSchema={ForgotPasswordSchema}
                            onSubmit={(values, actions) => {
                                setTimeout(() => {
                                    actions.setSubmitting(false);
                                }, 1000);

                                forgotPassword(values.email, successCallBack)
                            }}
                        >
                            {({errors, touched, isSubmitting}) => (
                                <Form>
                                    <div className="form-group">
                                        <Field
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                                        />
                                        <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                                    </div>
                                    <div className="form-group">
                                        <button
                                            className="btn btn-primary btn-block w-100"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting && (
                                                <FontAwesomeIcon className="fa-spin me-2" icon={faSpinner}/>
                                            )}
                                            Submit
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <div className="text-center forgotpass">
                            <Link to="/">
                                <a>Already user? Login</a>
                            </Link>
                        </div>
                        <div className="login-or">
                            <span className="or-line"></span>
                            <span className="span-or">or</span>
                        </div>
                        <div className="text-center dont-have">
                            Don’t have an account?
                            <Link to="/register"> <a>Register</a></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ForgotPassword;
