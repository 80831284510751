import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {isLoaded, useFirestoreConnect} from "react-redux-firebase";
import {TableNames} from "../../helper/TableNames";
import {useSelector} from "react-redux";
import Loader from "../../components/Loader";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';

const ClinicPatientDetails = () => {
    const isAdmin = sessionStorage.getItem('isAdmin')
    const {patientId, clinicIdFromAdmin} = useParams()
    const clinicId: any = sessionStorage.getItem('clinicid')
    const clinic_id = clinicIdFromAdmin ? clinicIdFromAdmin : clinicId

    let patientInfo: any = []

    const navigate = useNavigate();
    useFirestoreConnect({
        collection: TableNames.Clinics,
        doc: clinic_id,
        subcollections: [{collection: TableNames.ClinicPatients}],
        where: ['id', '==', patientId],
        storeAs: 'PatientDetails'
    });
    let patientDetails = useSelector((state: any) => state.firestore.data['PatientDetails']);
    if (isLoaded(patientDetails)) {
        patientInfo = Object.values(patientDetails).filter((item: any) => item['id'] === patientId);
        patientInfo = patientInfo[0]
    }
    {
        if (isLoaded(patientDetails) && patientInfo) {
            return (
                <>
                    <div className="page-header">
                        <h3 className="page-title">
                            {isAdmin == 'false' && (
                            <span className="text-dark cursor-pointer pe-3"
                                  onClick={() => {
                                      navigate(-1)
                                  }
                                  }>
                            <FontAwesomeIcon icon={faChevronLeft} size="sm"/>
                            </span>
                            )}
                            {isAdmin == 'true' && (
                                <span className="text-dark cursor-pointer pe-3"
                                      onClick={() => {
                                          navigate('/admin/view-clinic/'+clinic_id, {state: {openPage: 'patient-list'}})
                                      }
                                      }>
                            <FontAwesomeIcon icon={faChevronLeft} size="sm"/>
                            </span>
                            )}
                            Patient Details
                        </h3>

                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-12 col-md-6">
                                    {patientInfo && (
                                        <>
                                            <p>
                                                <strong>Name</strong> : {patientInfo?.firstName + ' ' + patientInfo?.middleName + ' ' + patientInfo?.lastName}
                                            </p>
                                            <p><strong>Email</strong> : {patientInfo?.email}</p>
                                        </>
                                    )}

                                </div>
                                <div
                                    className="col-12 col-md-6 d-flex justify-content-end align-items-start buttons-wrap">
                                    <button type="button" className="btn btn-primary mr-10">Download</button>
                                    <button type="button" className="btn btn-primary" onClick={() => {
                                        window.print()
                                    }}>Print Form
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {patientInfo?.clinicQuestions && patientInfo?.clinicQuestions.map((item: any) => {
                                        return (
                                            <>
                                                <p><b>{item.question}</b> : -</p>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>

                        </div>
                    </div>
                </>
            );
        } else {
            return <Loader/>
        }
    }

};
export default ClinicPatientDetails;
