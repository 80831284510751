import React, { useEffect, useRef, useState, FC, SetStateAction, Dispatch, useCallback, useMemo } from 'react'
import { withFormik, Field, Form, FormikValues, Formik, FieldProps, ErrorMessage, FormikProps, validateYupSchema } from 'formik'
import * as Yup from 'yup'
import { FormBuilderType, IFormBuilder } from '../../../models/FormBuilderType'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash, faSave, faUndo, faWindowClose, faArrowUp, faArrowDown, fas } from '@fortawesome/free-solid-svg-icons';
import { useFormBuilder } from '../../../hooks/formbuilder.hook.';
import { convertFirestoreDate, currentDate } from '../../../helper/firebasehelper';
import { trackPromise } from 'react-promise-tracker';
import ReactTagInput from "@pathofdev/react-tag-input";
import { confirmAlert } from 'react-confirm-alert';
import FormTest from '../../admin/FormTest';
import { useErrorHandling } from '../../../ErrorBoundary';





const AllFieldsSchema = (isSelected: boolean) => Yup.object().shape({
    question: Yup.string().required('Question is required'),
    answerCollection: isSelected ? Yup.array().min(1, 'Answers are Required').required() : Yup.array()

})


export const QuestionComponent = (props: {
    clinicId: string
    builder: IFormBuilder
    editQuestion: boolean
    isNewClicked: boolean
    totalRows: number
    isSelectAllChecked: boolean
    questionsSelected: IFormBuilder[]
    isPending: boolean | undefined
    questionsReadyToPublish: IFormBuilder[]
    callerMethods: any[]
    setActions: Dispatch<SetStateAction<any>>[]



}) => {

    const question = props.builder;
    const oldData = { ...props.builder }
    //Destructing the setActions
    const [setIsNewClicked, setEditQuestion, setIsSelectAllChecked, setQuestionsSelected] = [...props.setActions]
    //Destructing the callerMethods
    const [deleteQuestionFromList, refreshQuestions, updateQuestions, updatePositionsHandler,] = [...props.callerMethods]
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const [totalRows] = [props.totalRows]
    const [isReadyOnly, setIsReadyOnly] = useState(true)
    const [isReadyToSave, setIsReadytoSave] = useState(false)
    const [schema, setSchema] = useState(AllFieldsSchema(false));
    const { triggerError } = useErrorHandling();


    let QuestionsSelected = props.questionsSelected

    const answersArray = Array.from(question.answerCollection)
    const [tags, setTags] = React.useState(answersArray)


    const { saveQuestionToClinic, updateClinicQuestion } = useFormBuilder();

    const [isSaved, setIsSaved] = useState(false)
    type FormModel = {};

    var selectOption = false;
    //   console.log(question)

    //Set tags based on isSelection state
    const handleDropdownChange = (value: string) => {

        try {

            changeSelectedOption(value)
            if (selectOption) {
                setSchema(AllFieldsSchema(true))
                setTags([])
            } else {
                setSchema(AllFieldsSchema(false))
                setTags(['N\\A'])
            }
        } catch (error) {
           // triggerError(error)
        }

    }

    const changeSelectedOption = (value: string) => {


        if ((value == 'select') || (value == 'checkbox')) {
            selectOption = true

        } else {

            selectOption = false;


        }
    }

    const handleCheckChange = (e: any) => {
        const value = e.target.checked;

        try {
            question.isChecked = e.target.checked;


            if (value) {
                QuestionsSelected.push(question)

            } else {
                setIsSelectAllChecked(false)
                QuestionsSelected= QuestionsSelected.filter((item) => item.id !== question.id);

            }
            setQuestionsSelected(QuestionsSelected)

            console.log(QuestionsSelected)

        } catch (error) {
          //  triggerError(error)

        }


    }


    var isDisabled = false;
    useEffect(() => {
        if (props.isNewClicked) {
            setIsReadyOnly(false);

            scrollToBottom()

        }
        question.id === '' ? setIsReadytoSave(true) : setIsReadytoSave(false)

        question.answerFieldType === 'select' || question.answerFieldType === 'checkbox' ? selectOption = true : selectOption = false
        isDisabled = props.isNewClicked || props.editQuestion


    }, [question.isChecked, props.isSelectAllChecked]);

    function scrollToBottom() {

        window.scrollTo(0, document.body.scrollHeight + 200);
        window.history.scrollRestoration = "manual";
    }


    //To move rows up and down

    const moveUp = (e: any) => {
        let position = question.sequence;


        if (position === 1) return;
        else
            position = position - 1;

        updatePositionsHandler(question.id, position, 'up')

    }




    const moveDown = (e: any) => {
        let position = question.sequence

        if (position === totalRows) return;
        else
            position = position + 1;

        updatePositionsHandler(question.id, position, 'down')


    }


    //Shows the confirmation message and calls the parent method for update
    const updateQuestion = (dataObject: FormBuilderType, action: FormikValues) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h3>Please check the details before you proceed..</h3>
                        <button className="btn btn-outline-danger" onClick={() => {
                            onClose()
                            setIsSaved(false)


                        }}>Cancel</button>

                        <button className="btn btn-primary" type='button'
                            onClick={() => {
                                dataObject.isPublished = false;

                                trackPromise(

                                    updateClinicQuestion(props.clinicId, dataObject, dataObject.id).then((data) => {

                                        setEditQuestion('')
                                        setIsReadytoSave(false);
                                        setIsReadyOnly(true);
                                        setIsSaved(true)

                                    }))
                                onClose();


                            }}
                        >
                            Update
                        </button>
                    </div>
                );
            }
        })
    }


    //Shows the confirmation message on delete question and calls the parent delete method

    const deleteQuestion = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h3>Are you sure, you want to delete this Question?</h3>
                        <button className="btn btn-outline-danger" onClick={onClose}>No</button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                question.isActive = false;

                                trackPromise(

                                    updateClinicQuestion(props.clinicId, question, question.id).then(() => {
                                        deleteQuestionFromList(question.id, question.sequence)
                                    }))


                                onClose();
                            }}
                        >
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        })
    }

    //Format the Input Tags
    const formatTags = (tags: any) => {
        const listItems = tags.map((tag: any, index: number) =>
            <li key={index}>{tag}</li>
        );
        return (
            <ul className="list-unstyled">{listItems}</ul>
        );

    }


    return (


        <>

            <Formik
                initialValues={
                    {
                        id: question.id || '',
                        question: question.question || '',
                        answerFieldType: question.answerFieldType ? question.answerFieldType : 'textbox',
                        answerCollection: question.answerCollection || ['N\A'],
                        createdOn: question.createdOn,
                        isActive: question.isActive,
                        sequence: question.sequence,
                        isGeneric: question.isGeneric,
                        isPublished: question.isPublished,
                        isPending: props.isPending,
                        isDisabled: props.editQuestion,
                        isChecked: question.isChecked,
                        isReadyToPublish: false,
                        action: '',

                    }
                }


                validationSchema={schema}

                onSubmit={(values: FormikValues, actions) => {

                    let actionType = values.action;
                    actions.validateField('answerCollection')
                    if (selectOption) {
                        values.answerCollection = tags;
                    } else {
                        values.answerCollection = ['N\\A'];

                    }

                    const dataObject: FormBuilderType =
                        new FormBuilderType(values.id, values.question,
                            values.answerFieldType, values.answerCollection, values.sequence, values.isGeneric, values.isPublished, currentDate, true);


                    //Updating the existing question
                    if (actionType === 'save' && dataObject.id !== '') { 
                        updateQuestion(dataObject, actions)
                   

                    } else if (actionType === 'save' && dataObject.id === '') {


                        //Saves the new question
                        trackPromise(
                            saveQuestionToClinic(props.clinicId, dataObject).then((data) => {

                                setIsNewClicked(false)
                                setIsReadytoSave(false);
                                refreshQuestions()

                            })
                        )
                    }




                }}
            >
                {({ errors, touched, setFieldValue, values, handleReset, handleChange, dirty, isSubmitting }) => (

                    <Form  >
                      
                        {
  
                            changeSelectedOption(values.answerFieldType)
                        }



                        <div className="row align-items-center">
                            <div className='col-lg-1 col-md-1 text-center' >
                                {!props.isNewClicked && <input type="checkbox" name={question.id} checked={question.isChecked} onChange={(event: any) => {
                                    //call the formik handleChange event to get the default behavior
                                    setFieldValue('isChecked', event.target.checked)
                                 
                                    // Call the custom onChange event handler
                                    handleCheckChange(event)
                                }}


                                ></input>}
                            </div>
                            <div className='col-1 text-center' >
                                <button className="btn p-0" hidden={question.id === '' ? true : false} type='button' value={question.sequence} onClick={moveUp}>

                                    <FontAwesomeIcon title='up' cursor='hand' icon={faArrowUp} />


                                </button>
                                <button className='btn p-0' hidden={question.id === '' ? true : false} type='button' onClick={moveDown}>

                                    <FontAwesomeIcon title='down' cursor='hand' icon={faArrowDown} />

                                </button>
                            </div>
                            <div className="col-3 text-break "   >
                                {
                                    isReadyOnly ?
                                        <span  >{values.question}</span>
                                        :
                                        <>
                                            <Field hidden={isReadyOnly} component="textarea" rows="3" name='question' placeholder='Question' className={'form-control' + (touched.question && errors.question ? ' is-invalid' : '')} ></Field>
                                            <ErrorMessage name="question" component="div" className="invalid-feedback" />

                                        </>
                                }


                            </div>
                            <div className="col-2 text-capitalize"   >

                                {
                                    isReadyOnly ?
                                        <span>{values.answerFieldType}</span>
                                        :
                                        <div>
                                            <Field className="form-select form-select-sm" component='select' name="answerFieldType" onChange={(event: any) => {

                                                handleChange(event)
                                                handleDropdownChange(event.target.value)
                                                setFieldValue('answerCollection', '[]')


                                            }}  >
                                                <option value="textbox">TextBox</option>
                                                <option value="textarea">TextArea</option>
                                                <option value="select">Select </option>
                                                <option value="checkbox">Checkbox</option>

                                            </Field>


                                        </div>
                                }


                            </div>
                            <div className="col-2 text-break text-capitalize">

                                {
                                    isReadyOnly ?
                                        <span  >{formatTags(tags)}</span>
                                        : <>

                                            <div hidden={!selectOption}  >
                                                <ReactTagInput
                                                    placeholder=' '
                                                    tags={tags}
                                                    readOnly={isReadyOnly}

                                                    onChange={(newTags) => {
                                                        setTags(newTags)
                                                        values.answerCollection = newTags
                                                        setFieldValue('answerCollection', newTags)

                                                        if (values.answerFieldType == 'select' || values.answerFieldType == 'checkbox') {

                                                            setSchema(AllFieldsSchema(true))
                                                        } else {
                                                            setSchema(AllFieldsSchema(false))

                                                        }

                                                    }

                                                    }


                                                />
                                            </div>
                                            <input className="form-control" type='text' hidden={selectOption} defaultValue='N\A' readOnly />


                                            <ErrorMessage name="answerCollection" component="div" className='field-error' />

                                        </>
                                }




                            </div>
                            <div className='col-1'>
                                {convertFirestoreDate(question.createdOn)}
                            </div>
                            <div className="col-1 text-capitalize">
                               
                                {
                                    isReadyToSave && <>

                                        {!isSaved ? <button type="submit" title='Save' onClick={() => {

                                            setIsSaved(false)

                                            setFieldValue('action', 'save')
                                            if (values.answerFieldType == 'select' || values.answerFieldType == 'checkbox') {

                                                setSchema(AllFieldsSchema(true))
                                            } else {
                                                setSchema(AllFieldsSchema(false))

                                            }


                                        }} className="btn btn-sm btn-primary">
                                            <FontAwesomeIcon icon={faSave} />
                                        </button>
                                            :
                                            <div className="spinner-border text-primary">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>}
                                    </>

                                }
                                {
                                    isReadyToSave && values.id !== '' &&
                                    <button title='Cancel' type='button' onClick={() => {
                                        handleReset()
                                        // setEditQuestion('')
                                        setIsSaved(false)

                                        setIsReadyOnly(true)
                                        setIsReadytoSave(false)
                                        setTags(question.answerCollection)
                                        setFieldValue("answerFieldType", question.answerFieldType)
                                        refreshQuestions()



                                    }} className="btn btn-sm btn-outline-secondary">
                                        <FontAwesomeIcon icon={faWindowClose} />
                                    </button>

                                }
                                {

                                    !isReadyToSave && <>
                                        <button type="button" title='Edit' disabled={isDisabled} onClick={() => {

                                            setIsSaved(false)

                                            setIsReadytoSave(true)
                                            setIsReadyOnly(false)
                                            setEditQuestion(values.id)
                                        }} className="btn btn-sm btn-outline-secondary">
                                            <FontAwesomeIcon icon={faPencilAlt} />
                                        </button>
                                        <button type="button" disabled={isDisabled} title='Delete' onClick={() => {
                                            deleteQuestion();
                                            setFieldValue('action', 'delete')
                                        }} className="btn btn-sm btn-outline-secondary">
                                            <FontAwesomeIcon icon={faTrash} />

                                        </button>


                                    </>

                                }


                            </div>
                            <div className="col-1 text-capitalize">

                                {

                                    question.isPublished ? <span className="badge badge-success ml-3">Published</span> : <span className="badge badge-danger ml-3">Pending</span>

                                }
                            </div>
                        </div>


                    </Form>
                )}
            </Formik>
        </>

    )
}



