import React from 'react'
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { TableNames } from '../helper/TableNames';


const ViewLogs = () => {

    let errorsList: any[] = [];
    useFirestoreConnect({
        collection: TableNames.Errors,
        
        storeAs: "Errors",
        orderBy:[            
            ['createdOn', 'desc']
          ] ,
    });

    let errors = useSelector((state: any) => state.firestore.data['Errors']);
    if (isLoaded(errors)) {
        errorsList = Object.values(errors)

        console.log(errorsList)
    }
    return (
        <div className="login-wrapper">
            <div className="container">
                <div className='row'>


                    <ul>
                        {
                            errorsList && errorsList.map((item: any, index: number) => {

                                return <li key={index}>
                                    <div className='col'>{item.message}</div>
                                    <div className='col'>{item.stack}</div>



                                </li>
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ViewLogs