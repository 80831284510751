import {IAddress} from "./ClinicType";
import firebase from 'firebase';



export interface IPatient {
    id: string
    firstName: string,
    lastName: string,
    middleName: string,
    DOB: string,
    gender: string,
    phone: string
    email: string
    address?: IAddress,
    createdOn?: firebase.firestore.Timestamp,
    isActive?: boolean,
    isDeleted?: boolean,
    secretKey?: string,
    isCheckedFrom?: string,

}

export class PatientType implements IPatient {
    constructor(
        public id: string,
        public firstName: string,
        public lastName: string,
        public middleName: string,
        public DOB: string,
        public gender: string,
        public phone: string,
        public email: string,
        public address: IAddress,
        public createdOn?: firebase.firestore.Timestamp,
        public secretKey?: string) {
    }

    isActive?: boolean | undefined;
    isDeleted?: boolean | undefined;
    isCheckedFrom?: string | undefined


}
