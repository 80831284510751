import React, { Fragment } from "react";

const CheckBox = ({ field,field_id}:{field:any,field_id:any}) => {
  return (
    <div>
     
      {field.map((option:any, index:number) => {
        return (
          <Fragment key={option}>
            <label htmlFor={option}>
              <input
                type="checkbox"
                id={option}
                name={field_id}
              />
              {option}
            </label>
          </Fragment>
        );
      })}
    </div>
  );
};

export default CheckBox;
