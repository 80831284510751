import { FormBuilderType, IFormBuilder } from "../models/FormBuilderType";
import { FirestoreClient } from '../library/FirestoreClient'
import firebase from 'firebase';
import { TableNames } from "../helper/TableNames";
import { useSelector } from "react-redux";
 
const db = firebase.firestore();


const convertQuestionObjectToStringObject = (formBuilder: IFormBuilder) => {

    const stringObject = {

        'question': formBuilder.question,
        'answerFieldType': formBuilder.answerFieldType,
        'answerCollection': formBuilder.answerCollection,
        'sequence': formBuilder.sequence,
        'isGeneric': formBuilder.isGeneric,
        'isPublished': formBuilder.isPublished,
        'createdOn': formBuilder.createdOn,
        'isActive': formBuilder.isActive
        

    }
    return stringObject;
}

const convertStringToFormbuilderType = (object: any): FormBuilderType => {

    const newQuestion = new FormBuilderType(
        object['id'],
        object['question'],
        object['answerFieldType'],
        object['answerCollection'],
        object['sequence'],
        object['isGeneric'],
        object['isPublished'],
        object['createdOn'],      
        object['isActive'],
        false

    )
    return newQuestion;
}

export const useFormBuilder = () => {

    //Get the questions from clinics/clinicquestions collection.These are the questions shown in formbuilder page
    const getQuestionsFromClinics = async (clinicId: string) => {

        let results: any[] = [];

        const dataCode = await FirestoreClient.getSubCollectionData(TableNames.Clinics, clinicId, TableNames.ClinicQuestions).then((data) => {

            data.forEach(async (element: any) => {
                results.push(
                    new FormBuilderType(
                        element['docid'],
                        element['question'],
                        element['answer-field-type'],
                        element['answer-collection'],
                        element['sequence'],
                        element['is-generic'],
                        element['is-published'],
                        element['created-on'],
                        element['is-active'],
                        false
                       

                    )
                )
            })
            return results;

        })
        results.sort((a, b) => a.sequence - b.sequence)

        return results;


    }

 

    //Returns all the questions from Questions table with status is-active=true
    const getAllGenericQuestions = async () => {


        let results: FormBuilderType[] = [];
        const questionsData = await FirestoreClient.getAll(TableNames.Questions)
            .then((questionsCollection) => {

                questionsCollection.forEach((element: any) => {

                    results.push(
                        new FormBuilderType(
                            element['id'],
                            element['question'],
                            element['answer-field-type'],
                            element['answer-collection'],
                            element['sequence'],
                            element['is-generic'],
                            element['created-on'],
                            element['is-active']

                        )
                    )
                });

            })
            .catch((error) => {

                console.log('Unable to load questions')
            });

        results.sort((a, b) => a.sequence - b.sequence)
        return results;

    }


    //Saving the question row
    const saveQuestion = async (formBuilder: IFormBuilder) => {

         var objectJson = convertQuestionObjectToStringObject(formBuilder);

        return await FirestoreClient.save(TableNames.Questions, objectJson )
    }

    //Updates the question document
    const updateClinicQuestion =   async(clinicId: string, formBuilder: IFormBuilder, id: string) => {

         var objectJson = convertQuestionObjectToStringObject(formBuilder);
          

         const data=  await FirestoreClient.updateSubCollectionData(TableNames.Clinics, clinicId,  TableNames.ClinicQuestions, objectJson, id).then((data)=>{

            return data;
         })
         .catch((error)=>{
            console.log("error",error)
         })
         return data;
    }



    //Gets question from questions firebase collection

    const getQuestion = async (id: string) => {
        const question: any = await FirestoreClient.getRow(TableNames.Questions, id).then((data) => {

            return data;
        });
     //   return convertStringToFormbuilderType(question)

    }
 

    //Method to save the Question to clinics\clinicquestions collection
    const saveQuestionToClinic = async (clinicId: string, question: IFormBuilder) => {

        
        const stringObject = convertQuestionObjectToStringObject(question);
        //Add new property 'is-published' to the question object
        const objectWithPublished = { ...stringObject, 'is-published': false }
        
        
        FirestoreClient.saveSubCollection(TableNames.Clinics, clinicId, TableNames.ClinicQuestions, objectWithPublished).then((result) => {
            console.log(result)
        })
    }
    return {
        convertStringToFormbuilderType,
        getAllGenericQuestions,
        saveQuestion,
        updateClinicQuestion,
        getQuestion,
        saveQuestionToClinic,
        getQuestionsFromClinics,

    }
}

 