import {firestore} from "firebase";


export const convertFirestoreDate = (time: firestore.Timestamp | undefined) => {

  
  return time === undefined ? '' : time.toDate().toLocaleString().split(',')[0];
}

export const convertFirestoreTimestampToDate=(time: firestore.Timestamp)=>{
    return  time ? new Date(time?.seconds*1000).toLocaleDateString() :' N\\A'
}

export const currentDate = firestore.Timestamp.fromDate(new Date());

export const randomId = () => {
    var ts = String(new Date().getTime()),
        i = 0,
        out = '';

    for (i = 0; i < ts.length; i += 2) {
        out += Number(ts.substr(i, 2)).toString(36);
    }

    return ('opioid' + out);
}

function getFormattedDate(date:any) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return month + '/' + day + '/' + year;
}