import React from 'react';
import { currentDate } from './helper/firebasehelper';
import { FirestoreClient } from './library/FirestoreClient';

const ErrorBoundaryContext = React.createContext<any>(() => { });
export const useErrorHandling = () => {
    return React.useContext(ErrorBoundaryContext)
}

class ErrorBoundary extends React.Component {

    state = { hasError: false };

    static getDerivedStateFromError(error: any) {

        return { hasError: true };
    }
    componentDidCatch(error: any) {

        const errorInfo = {
            message: error.message,
            stack: error.stack,
            createdOn:currentDate
        }
        FirestoreClient.save('Errors', errorInfo)

    }
    triggerError = (error: any) => {

        const errorInfo = {
            message: error.message,
            stack: error.stack,
            createdOn:currentDate

        }
        FirestoreClient.save('Errors', errorInfo)
        this.setState({ hasError: true });
    }
    render() {
        return (
            <ErrorBoundaryContext.Provider value={this.triggerError}>
                {/* {this.state.hasError
                    ? window.location.href = "/error.html"
                    : this.props.children
                } */}


               { this.props.children}
            </ErrorBoundaryContext.Provider>
        );
    }

}
export default ErrorBoundary;
