import firebase from 'firebase';

import '../config/firebase'
import { toast } from "react-toastify";
import { config } from '@fortawesome/fontawesome-svg-core';
import { FirebaseSecondary } from '../config/firebase';

const db = firebase.firestore();

export class FirestoreClient {

    static submitForm = async (data: any, collection: any, rootCollection: any, subCollection: any, subColData: any, subColIdKey: any, uniqueField: any, clinicId: any) => {
        const objectJson = JSON.parse(JSON.stringify(data));
        const docRef = db.collection(collection)
        const response = await docRef.where(uniqueField, '==', data[uniqueField]).get().then(snap => {
            console.log('snap', snap)
            if (!snap.size) {
                console.log('if snap', snap)
                FirestoreClient.save(collection, objectJson)
                    .then((id: any) => {
                        subColData[subColIdKey] = id
                        return FirestoreClient.saveSubCollection(rootCollection, clinicId, subCollection, subColData)
                    })
                    .catch((error) => {
                        return error
                    });
            } else {
                console.log('else snap', snap)
                return 'error'
            }
        });
        return response
    }


    static getRowCount = async (collection: string) => {
        const count = await db.collection(collection).get().then(snap => {
            // return { length: snap.size };
            return snap.size;
        });
        return count;
    }

    static getActiveRowCount = async (collection: string) => {
        const count = await db.collection(collection).where('isActive',"==",true).get().then(snap => {
            // return { length: snap.size };
            return snap.size;
        });
        return count;
    }
    static getRowByField = async (collection: string, field: string, value: string) => {

        const data: any[] = [];

        const snapshot = await db.collection(collection).where(field, '==', value).get()

        if (snapshot.empty) {
            console.log('No matching documents.');
            return '';
        }

        snapshot.forEach(doc => {
            data.push(doc.data())

        });
        return data[0];

    }
    static getRowCountByField = async (collection: string, field: string, value: any) => {

        const docRef = db.collection(collection)

        const count = await docRef.where(field, '==', value).get().then(snap => {
            // return { length: snap.size };
            return snap.size;
        });
        return count;
    }

    static getRow = async (collection: any, id: any) => {
        const row = db.collection(collection).doc(id)
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    return {
                        id: id,
                        ...doc.data()
                    }
                } else {
                    return ''
                }
            }).catch(function (error) {
                console.log("Error getting document:", error);
            });

        return row;

    }
    static getAll = async (collection: string) => {
        let results: any = [];

        await db.collection(collection).where('isActive', "!=", false).get()
            .then((resultData) => {

                resultData.forEach((doc: any) => {

                    results.push(
                        {
                            id: doc.id,
                            ...doc.data()
                        }
                    )
                });
            }).catch((error) => {

                return error;

            })

        return results;
    }

    static getSubCollections = async (collection: string, id: any, subCollection: string) => {
        let results: any = [];
        let path: any = '/' + collection + '/' + id + '/' + subCollection

        await db.collection(path).get()
            .then((resultData) => {
                resultData.forEach((doc: any) => {
                    results.push(
                        {
                            id: doc.id,
                            ...doc.data()
                        }
                    )
                });
            }).catch((error) => {
                return error;
            })
        return results;
    }
    //Adding id field to every collection and subcollection and updating it with documentid
    static save = async (collection: any, data: any) => {
        const docRef = db.collection(collection).doc();

        const id = await docRef.set(data).then((docuRef) => {
            docRef.update({
                id: docRef.id,
            });
            return docRef.id;
        }).catch((error) => {
            console.log(error)
            return 'error'
        });
        return id;

    }
    static delete = async (collection: any, id: string) => {
        const res = await db.collection(collection).doc(id).delete()
            .then(() => {
                return 'success'
            })
            .catch((error) => {
                return 'error'
            });

    }
    static deleteSubCollectionDoc = async (rootCollection: string, rootId: string, subCol: any, docId: string) => {

        console.log("in delete", docId)
        var docRef = db.collection(rootCollection).doc(rootId).collection(subCol);

        // delete the document
        await docRef.doc(docId).delete()
            .then(() => {
                return 'success'
            })
            .catch((error) => {
                return 'error'
            });

    }


    static update = async (collection: any, data: any, id: any) => {

        const ref = db.collection(collection).doc(id);
        const res = await ref.update(data).then((res) => {
            return 'success';
        }).catch((error) => {
            FirestoreClient.errorCallback(error)
            return 'error';
        });
        return res
    }



    //Adding id field to every collection and subcollection and updating it with documentid
    static saveSubCollection = async (rootCollection: any, rootDocName: any, subCol: any, subColData: any) => {
        const docRef = db.collection(rootCollection).doc(rootDocName).collection(subCol).doc();
        const response = await docRef.set(subColData).then((res) => {
            docRef.update({
                id: docRef.id,
            });
            return docRef.id;
        }).catch((error) => {
            return error;
        });
        return response
    }

    static updateSubCollection = async (rootCollection: string, id: any, subCol: string, subColData: any, docId: string) => {
        const ref = db.collection(rootCollection).doc(id).collection(subCol).doc(docId)

        const response = await ref.update(subColData).then((res) => {
            return res;
        }).catch((error) => {
            FirestoreClient.errorCallback(error)
            return 'error';
        });
        return response
    }

    static updateSubCollectionData = async (rootCollection: string, id: any, subCol: string, subColData: any, docId: string) => {
       const resut=  await db.collection(rootCollection + "/" + id + "/" + subCol + "/").where('id', "==", docId).get()
            .then((resultData) => {

                const ref = db.collection(rootCollection + "/" + id + "/" + subCol + "/").doc(docId)

                const response = ref.update(subColData).then((res) => {

                    return res;
                }).catch((error) => {
                    console.log("error")

                    FirestoreClient.errorCallback(error)
                    return 'error';
                });
                return response
            })

            return resut;
    }


    static updateSubSubCollection = async (rootCollection: string, id: any, subCol: string, subColData: any, docId: string) => {
        await db.collection(rootCollection + "/" + id + "/" + subCol + "/").where('DoctorId', "==", docId).get()
            .then((resultData) => {
                const documentID = resultData.docs[0].id
                const ref = db.collection(rootCollection + "/" + id + "/" + subCol + "/").doc(documentID)
                ref.update(subColData).then((res) => {
                    return res;
                }).catch((error) => {
                    FirestoreClient.errorCallback(error)
                    return 'error';
                });

            }).catch((error) => {
                FirestoreClient.errorCallback(error)
                return error;
            })
    }


    static getSubCollection = async (rootCollection: any, id: any, subCol: any) => {
        const rowsCollection = await db.collection(rootCollection).doc(id).collection(subCol).get().then((data) => {
            return data.docs;
        })
        return rowsCollection;
    }
    static getSubCollectionData = async (rootCollection: any, id: any, subCol: any) => {
        let results: any = [];
        await db.collection(rootCollection).doc(id).collection(subCol).where('isActive', "!=", false).get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    const docid = doc.id
                    results.push(
                        {
                            docid,
                            ...doc.data()
                        }
                    )
                });
            });

        return results;
    }

    static saveByPath = async (path: string, data: any) => {
        const dotRef = db.doc(path);
        await dotRef.set(data);

    }

    static getByPath = async (path: string) => {
        const dotRef = db.doc(path);
        const response = await dotRef.get();
        return response.data();
    }

    static errorCallback = (error: any) => {
        let errorMessage = error.message ? error.message : 'Something went wrong please try again'
        return toast.error(errorMessage)
    }

    static userRegister = async (email: string, password: string) => {
        const actionCodeSettings = {
            url: process.env.REACT_APP_URL ||'https://my-opioid-recovery.web.app/',
            handleCodeInApp: false
        }

        const userResponse = await FirebaseSecondary.auth().createUserWithEmailAndPassword(email, password)
            .then((response) => {
                //firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
                return response
            })
            .catch(function (error) {
                // Handle Errors here.
                FirestoreClient.errorCallback(error)
                return 'error'
            });
        return userResponse
    }

    static getSubCollectionRowByField = async (rootCollection: any, id: any, subCol: any, field: string, value: string) => {
        let results: any = [];
        await db.collection(rootCollection).doc(id).collection(subCol).where(field, "==", value).get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    const docid = doc.id
                    results.push(
                        {
                            docid,
                            ...doc.data()
                        }
                    )
                });
            });

        return results;

    }
}


//module.exports = new FirestoreClient();
