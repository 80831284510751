import * as React from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {FirestoreClient} from "../../library/FirestoreClient";
import {TableNames} from "../../helper/TableNames";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const ClinicCheckinSchema = Yup.object().shape({
    secretKey: Yup.string().required('Secret Key is required'),
});

const ClinicCheckin = () => {
    const clinicId: any = sessionStorage.getItem('clinicid')
    const navigate = useNavigate()
    return (
        <>
            <div className="page-header">
                <h3 className="page-title">Check In</h3>
            </div>
            <Formik
                initialValues={{
                    secretKey: '',
                }}
                validationSchema={ClinicCheckinSchema}
                onSubmit={values => {
                    FirestoreClient.getSubCollectionRowByField(TableNames.Clinics, clinicId, TableNames.ClinicPatients,'secretKey', values.secretKey).then((data) => {

                        if(data.length == 0){
                            toast.error('Patient not found, Please try again')
                        } else {
                            navigate("/clinic/patient-details/" + data[0].id)
                        }
                        return data;
                    }).catch(error => error)
                    //console.log(values);
                }}
                render={({ errors, touched }) => (
                    <Form>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Secret Key</label>
                                            <Field
                                                type="text"
                                                name="secretKey"
                                                className={'form-control' + (errors.secretKey && touched.secretKey ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="secretKey" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </Form>
                )}
            />
        </>
    );
};
export default ClinicCheckin;
