import firebase from 'firebase';
import { debug } from 'webpack';
import { DefinedBooleanSchema } from 'yup/lib/boolean';
const db = firebase.firestore();
 
export interface IFormBuilder{
    id:string
    question:string,
    answerFieldType:string,
    answerCollection:string[],
    sequence:number,
    isGeneric:boolean,
    isPublished?:boolean,
    createdOn?:firebase.firestore.Timestamp,
    isActive?:boolean,
    isChecked?:boolean,
  
}

export class FormBuilderType implements IFormBuilder
{
    id: string;
    question: string;
    answerFieldType: string;
    answerCollection: string[];
    sequence:number;
    isGeneric:boolean;
    isPublished?:boolean;
    createdOn?: firebase.firestore.Timestamp;
    isActive?: boolean;
    isChecked?:boolean;
  
   

    constructor(id: string, quesition: string, answerField: string,answers:string[],position:number,isGeneric:boolean, isPublished?:boolean,createdDate?:firebase.firestore.Timestamp,active?:boolean,checked?:boolean) {  
        this.id = id
        this.question = quesition
        this.answerFieldType = answerField 
        this.answerCollection=answers
        this.createdOn=createdDate
        this.isActive=active
        this.sequence=position
        this.isGeneric=isGeneric
        this.isPublished=isPublished
        this.isChecked=checked
    }  
}

