import React, {useEffect, useState, useMemo} from "react";

const PaginationComponent = ({
                                 total = 0,
                                 itemsPerPage = 10,
                                 currentPage = 1,
                                 onPageChange
                             }) => {
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        if (total > 0 && itemsPerPage > 0)
            setTotalPages(Math.ceil(total / itemsPerPage));
    }, [total, itemsPerPage]);

    const paginationItems = useMemo(() => {
        const pages = [];

        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li
                    class={i === currentPage ? 'page-item active' : 'page-item'}
                    key={i}
                    active={i === currentPage}
                    onClick={() => onPageChange(i)}
                >
                    <button type="button" class="page-link">
                        {i}
                    </button>
                </li>
            );
        }

        return pages;
    }, [totalPages, currentPage]);

    if (totalPages === 0) return null;

    return (
        <>
            <div className="d-flex justify-content-center">
                <div>
                    <ul class="pagination">
                        <li class="page-item">
                            <button type="button" class="page-link"
                                    onClick={() => onPageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                            >
                                <span aria-hidden="true">‹</span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                        </li>
                        {paginationItems}
                        <li class="page-item">
                            <button type="button" class="page-link"
                                    onClick={() => onPageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                            >
                                <span aria-hidden="true">›</span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default PaginationComponent;
