import * as React from 'react';

const ClinicInfo = (props: any) => {
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <p><b>Clinic Name</b> : {props?.clinic?.clinicName}</p>
                            <p><b>Email</b> : {props?.clinic?.clinicEmail}</p>
                            <p><b>Phone</b> : {props?.clinic?.clinicContact}</p>
                            <p>
                                <b>Address</b> :
                                {props?.clinic?.address?.address1 ? props?.clinic?.address?.address1 + ', ' : ''}
                                {props?.clinic?.address?.address2 ? props?.clinic?.address?.address2 + ', ' : ''}
                                {props?.clinic?.address?.city ? props?.clinic?.address?.city + ', ' : ''}
                                {props?.clinic?.address?.state ? props?.clinic?.address?.state + ', ' : ''}
                                {props?.clinic?.address?.zip ? props?.clinic?.address?.zip : ''}
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};
export default ClinicInfo;
