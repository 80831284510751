import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'

import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useCommon } from "../../hooks/common.hooks";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FirestoreClient } from "../../library/FirestoreClient";
import { useClinic } from '../../hooks/clinic.hook';
import { TableNames } from '../../helper/TableNames';
import { statesList } from "../../constants";
import { PatientType } from "../../models/PatientType";
import { ClinicType, IAddress, IClinic } from "../../models/ClinicType";
import { currentDate } from "../../helper/firebasehelper";

const AdminClinicSchema = Yup.object().shape({
    clinicName: Yup.string().required('Clinic Name is required'),
    clinicContact: Yup.string()
        .required('Clinic Contact is required')
        .min(10, 'Clinic Contact should be 10 digits')
        .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            "Clinic Contact is invalid"
        )
        .typeError('Clinic Contact must be in digits'),
    clinicEmail: Yup.string().email('Invalid email').required('Email is required'),
    Address1: Yup.string().required('Address is required'),
    City: Yup.string().required('City is required'),
    State: Yup.string().required('State is required'),
    Zip: Yup.string()
        .required('Zip is required')
        .min(5, 'Zip should be minimum 5 digits')
        .matches(
            /^\d{5}(?:[-\s]\d{4})?$/, "Zip is invalid"
        ),
    other: Yup.string().when("businessStatus", {
        is: (value: any) => value == "other",
        then: Yup.string().required('Other is required')
    }),

    website: Yup.string()
        .matches(
            /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
            'Enter correct url!'
        ),
    customs: Yup.array()
        .of(
            Yup.object().shape({
                label: Yup.string(),
                content: Yup.string(),
            })
        ),
});

const AdminCreateClinic = (props: any) => {
    const location = useLocation()
    const id = location?.state?.id
    const pageTitle = id ? 'Update Clinic' : location.pathname == '/register' ? 'Register' : 'Create Clinic'
    const toastMessage = id ? 'Updated Clinic Successfully' : 'Created Clinic Successfully'
    const backLinkUrl = location.pathname == '/register' ? '/' : '/admin/dashboard'

    const { pushGenericQuestionsToClinicQuestions } = useClinic();

    const navigate = useNavigate()

    const convertClinicObjectToStringObject = (clinic: IClinic) => {
        const stringObject = {
            'clinicName': clinic.clinicName,
            'clinicContact': clinic.clinicContact,
            'clinicEmail': clinic.clinicEmail,
            'businessStatus': clinic.businessStatus,
            'other': clinic.other,
            'website': clinic.website,
            'address': clinic.address,
            'customs': clinic.customs,
            'isActive': clinic.isActive,
            'isApproved': clinic.isApproved,
            'isDeleted': false,
            'createdOn': clinic.createdOn,
        }
        return stringObject;
    }

    const successCallback = async (ClinicId: any) => {
        toast.success(toastMessage)

        //Setting the clinic id into localstorage.
        sessionStorage.setItem('clinicid', ClinicId);
        await pushGenericQuestionsToClinicQuestions(ClinicId)

        if (location.pathname == '/register') {
            navigate(backLinkUrl)
        } else {
            //Checking the clinicquestions collection inside the clinics for generic questions.
            //If no default questions are added push them from questions collection

            setTimeout(() => {
                window.location.href = backLinkUrl;

            }, 1000);
            // navigate(backLinkUrl)
        }
    }

    return (
        <>
            <div className="page-header">
                <h3 className="page-title">{pageTitle}</h3>
            </div>
            <Formik
                initialValues={{
                    clinicName: location?.state?.clinicName ? location?.state?.clinicName : '',
                    clinicContact: location?.state?.clinicContact ? location?.state?.clinicContact : '',
                    clinicEmail: location?.state?.clinicEmail ? location?.state?.clinicEmail : '',

                    Address1: location?.state?.address.address1 ? location?.state?.address.address1 : '',
                    Address2: location?.state?.address.address2 ? location?.state?.address.address2 : '',
                    City: location?.state?.address.city ? location?.state?.address.city : '',
                    State: location?.state?.address.state ? location?.state?.address.state : '',
                    Zip: location?.state?.address.zip ? location?.state?.address.zip : '',

                    businessStatus: location?.state?.businessStatus ? location?.state?.businessStatus : '',
                    other: location?.state?.other ? location?.state?.other : '',
                    website: location?.state?.website ? location?.state?.website : '',
                    customs: location?.state?.customs ? location?.state?.customs : [{ label: "", content: "" }],

                    isActive: location?.state?.isActive ? location?.state?.isActive : true,
                    isApproved: location?.state?.isApproved ? location?.state?.isApproved : true,
                    isDeleted: location?.state?.isDeleted ? location?.state?.isDeleted : false,
                    createdOn: location?.state?.createdOn ? location?.state?.createdOn : currentDate,
                }}
                validationSchema={AdminClinicSchema}
                onSubmit={(values, actions) => {
                    const addressObject = {
                        'address1': values.Address1,
                        'address2': values.Address2,
                        'city': values.City,
                        'state': values.State,
                        'zip': values.Zip,
                    }

                    const dataObject: ClinicType = new ClinicType(
                        id ? id : '',
                        values.clinicName,
                        values.clinicContact,
                        values.clinicEmail,
                        values.businessStatus,
                        values.other,
                        values.website,
                        values.customs,
                        addressObject,
                        values.createdOn,
                    )

                    dataObject.isActive = values.isActive
                    dataObject.isApproved = values.isApproved
                    dataObject.isDeleted = values.isDeleted

                    //const objectJson = JSON.parse(JSON.stringify(dataObject));
                    const objectJson = convertClinicObjectToStringObject(dataObject);

                    console.log(objectJson)

                    if (id) {
                        FirestoreClient.update(TableNames.Clinics, objectJson, id).then((res) => {
                            successCallback(res)
                        })
                    } else {
                        FirestoreClient.userRegister(values.clinicEmail, '123456').then((response) => {
                            if (response != 'error') {

                                FirestoreClient.save(TableNames.Clinics, objectJson).then((res) => {
                                    successCallback(res)
                                }).catch(error => {
                                    console.log(error)
                                    toast.error('Error occured while saving the clinic')
                                })
                            } else {
                                actions.setSubmitting(false)
                            }
                        })
                    }

                }}
            >
                {({ errors, status, touched, values, isSubmitting }) => (
                    <Form>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Clinic Name</label>
                                            <Field
                                                type="text"
                                                name="clinicName"
                                                className={'form-control' + (errors.clinicName && touched.clinicName ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="clinicName" component="div"
                                                className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Clinic Phone</label>
                                            <Field
                                                type="text"
                                                name="clinicContact"
                                                maxLength={10}
                                                className={'form-control' + (errors.clinicContact && touched.clinicContact ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="clinicContact" component="div"
                                                className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <Field
                                                type="email"
                                                name="clinicEmail"
                                                disabled={location?.state?.clinicEmail}
                                                className={'form-control' + (errors.clinicEmail && touched.clinicEmail ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="clinicEmail" component="div"
                                                className="invalid-feedback" />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Address 1</label>
                                            <Field
                                                type="text"
                                                name="Address1"
                                                className={'form-control' + (errors.Address1 && touched.Address1 ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="Address1" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Address 2</label>
                                            <Field
                                                type="text"
                                                name="Address2"
                                                className={'form-control' + (errors.Address2 && touched.Address2 ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="Address2" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>City</label>
                                            <Field
                                                type="text"
                                                name="City"
                                                className={'form-control' + (errors.City && touched.City ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="City" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>State</label>
                                            <Field
                                                component="select"
                                                name="State"
                                                className={'form-select' + (errors.State && touched.State ? ' is-invalid' : '')}
                                            >
                                                <option>Select State</option>
                                                {statesList.map(({ code, name }, index) => (
                                                    <option value={code}>{name}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="State" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Zip</label>
                                            <Field
                                                type="text"
                                                name="Zip"
                                                maxLength={10}
                                                className={'form-control' + (errors.Zip && touched.Zip ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="Zip" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Business Status</label>
                                            <Field
                                                component="select"
                                                name="businessStatus"
                                                className={'form-select' + (errors.businessStatus && touched.businessStatus ? ' is-invalid' : '')}
                                            >
                                                <option>Select Status</option>
                                                <option value="operational">Operational</option>
                                                <option value="underprogress">Under Progress</option>
                                                <option value="other">Other</option>
                                            </Field>
                                            <ErrorMessage name="businessStatus" component="div"
                                                className="invalid-feedback" />
                                        </div>
                                        {values.businessStatus == 'other' && (
                                            <div className="form-group">
                                                <label>Other</label>
                                                <Field
                                                    type="text"
                                                    name="other"
                                                    className={'form-control' + (errors.other && touched.other ? ' is-invalid' : '')}
                                                />
                                                <ErrorMessage name="other" component="div"
                                                    className="invalid-feedback" />
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Website</label>
                                            <Field
                                                type="text"
                                                name="website"
                                                className={'form-control' + (errors.website && touched.website ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="website" component="div"
                                                className="invalid-feedback" />
                                        </div>
                                    </div>

                                </div>
                                <hr />
                                <div className="add-more-wrapper">
                                    <FieldArray name="customs">
                                        {({ insert, remove, push }) => (
                                            <>
                                                <div className="row mb-2 d-none d-md-flex">
                                                    <div className="col-12 col-md-5">Custom Label</div>
                                                    <div className="col-12 col-md-5">Content</div>
                                                    <div className="col-12 col-md-2">
                                                    </div>
                                                </div>
                                                {values.customs.length > 0 && values.customs.map((custom: any, index: any) => {
                                                    return (
                                                        <>
                                                            <div className="row" key={index}>
                                                                <div className="col-12 col-md-5">
                                                                    <div className="form-group form-group-array">
                                                                        <ErrorMessage
                                                                            name={`customs.${index}.label`}
                                                                            component="div"
                                                                            className="invalid-feedback-array"
                                                                        />
                                                                        <Field
                                                                            name={`customs.${index}.label`}
                                                                            placeholder="Enter Custom label"
                                                                            type="text"
                                                                            className={'form-control'}
                                                                        />
                                                                        <ErrorMessage
                                                                            name={`customs.${index}.label`}
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-5">
                                                                    <div className="form-group form-group-array">
                                                                        <ErrorMessage
                                                                            name={`customs.${index}.content`}
                                                                            component="div"
                                                                            className="invalid-feedback-array"
                                                                        />
                                                                        <Field
                                                                            name={`customs.${index}.content`}
                                                                            placeholder="Enter content"
                                                                            type="text"
                                                                            // className="form-control"
                                                                            className={'form-control'}
                                                                        />
                                                                        <ErrorMessage
                                                                            name={`customs.${index}.content`}
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-2">
                                                                    {values.customs.length !== 1 && <button
                                                                        type="button"
                                                                        onClick={() => remove(index)}
                                                                        className="btn btn-danger"
                                                                    >
                                                                        <FontAwesomeIcon icon={faTrash} size="xs" />
                                                                        <span style={{
                                                                            marginLeft: '5px',
                                                                            fontSize: '13px'
                                                                        }}>Remove</span>
                                                                    </button>}
                                                                    {values.customs.length - 1 === index && <button
                                                                        type="button"
                                                                        className="btn btn-success"
                                                                        onClick={() => push({ label: "", content: "" })}
                                                                    >
                                                                        <FontAwesomeIcon icon={faPlus} size="xs" />
                                                                        <span style={{
                                                                            marginLeft: '5px',
                                                                            fontSize: '13px'
                                                                        }}>Add</span>
                                                                    </button>}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isSubmitting}>
                                    {isSubmitting && (
                                        <FontAwesomeIcon className="fa-spin me-2" icon={faSpinner} />
                                    )}
                                    Submit
                                </button>
                                <Link to={backLinkUrl} className="btn btn-danger">Cancel</Link>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};
export default AdminCreateClinic;
