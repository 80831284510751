import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash, faPlus, faSpinner} from '@fortawesome/free-solid-svg-icons'

import {Formik, Form, Field, ErrorMessage, FieldArray} from 'formik';
import * as Yup from 'yup';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {FirestoreClient} from "../../library/FirestoreClient";
import {TableNames} from "../../helper/TableNames";
import {DoctorType, IDoctor} from "../../models/DoctorType";
import {currentDate} from "../../helper/firebasehelper";

const ClinicDoctorSchema = Yup.object().shape({
    doctorName: Yup.string().required('Doctor Name is required')
        .matches(
            /^[a-zA-Z0-9 ]+$/,
            "Doctor Name is invalid"
        ),
    doctorContactNumber: Yup.string()
        .required('Mobile number is required')
        .min(10, 'Mobile number should be 10 digits')
        .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            "Mobile number is invalid"
        )
        .typeError('Mobile number must be in digits'),
    doctorEmail: Yup.string().email('Invalid email'),
    doctorCustoms: Yup.array()
        .of(
            Yup.object().shape({
                label: Yup.string().required("Label is required"),
                content: Yup.string().required("Content is required"),
            })
        )
        .required("Required"),
});

const ClinicCreateDoctor = () => {
    const clinicId = sessionStorage.getItem('clinicid')
    const navigate = useNavigate()
    const location = useLocation()
    const id = location?.state?.id
    const pageTitle = id ? 'Update Doctor' : 'Create Doctor'

    const toastMessage = id ? 'Updated Doctor Successfully' : 'Created Doctor Successfully'

    const convertDoctorObjectToStringObject = (doctor: IDoctor) => {
        const stringObject = {
            'id': doctor.id,
            'doctorName': doctor.doctorName,
            'doctorEmail': doctor.doctorEmail,
            'doctorContactNumber': doctor.doctorContactNumber,
            'doctorCustoms': doctor.doctorCustoms,
            'createdOn': doctor.createdOn,
            'isActive': doctor.isActive,
            'isApproved': false,
            'isDeleted': false,
        }
        return stringObject;
    }

    const successCallback = (data: any) => {
        toast.success(toastMessage)

        setTimeout(() => {
            window.location.href = "/clinic/amenities"

        }, 1000)
    }
    return (
        <>
            <div className="page-header">
                <h3 className="page-title">{pageTitle}</h3>
            </div>
            <Formik
                initialValues={{
                    doctorName: location?.state?.doctorName ? location?.state?.doctorName : '',
                    doctorContactNumber: location?.state?.doctorContactNumber ? location?.state?.doctorContactNumber : '',
                    doctorEmail: location?.state?.doctorEmail ? location?.state?.doctorEmail : '',
                    doctorCustoms: location?.state?.doctorCustoms ? location?.state?.doctorCustoms : [
                        {
                            label: "",
                            content: ""
                        }
                    ],
                    CreatedOn: location?.state?.CreatedOn ? location?.state?.CreatedOn : new Date(),
                    isActive: location?.state?.isActive ? location?.state?.isActive : true,
                    isDeleted: location?.state?.isDeleted ? location?.state?.isDeleted : false,
                    createdOn: location?.state?.createdOn ? location?.state?.createdOn : currentDate,
                }}
                validationSchema={ClinicDoctorSchema}
                onSubmit={(values, actions) => {

                    const dataObject: DoctorType = new DoctorType(
                        id ? id : '',
                        values.doctorName,
                        values.doctorEmail,
                        values.doctorContactNumber,
                        values.doctorCustoms,
                        values.createdOn
                    )
                    dataObject.isActive = values.isActive
                    dataObject.isDeleted = values.isDeleted

                    //const objectJson = JSON.parse(JSON.stringify(dataObject));
                    const objectJson = convertDoctorObjectToStringObject(dataObject);

                    if (id) {
                        FirestoreClient.updateSubCollection(
                            TableNames.Clinics,
                            clinicId,
                            TableNames.ClinicDoctors,
                            objectJson,
                            id
                        ).then((res) => {
                            successCallback(res)
                        })
                    } else {
                        FirestoreClient.save(TableNames.Doctors, objectJson)
                            .then((id: any) => {

                            })
                            .catch((error) => {
                                return error
                            });
                        FirestoreClient.saveSubCollection(
                            TableNames.Clinics,
                            clinicId,
                            TableNames.ClinicDoctors,
                            objectJson
                        ).then((response) => {
                            if (response != 'error') {
                                successCallback(response)
                            } else {
                                toast.error('Doctor already exists')
                                actions.setSubmitting(false)
                            }
                        }).catch((error) => {
                            actions.setSubmitting(false)
                            FirestoreClient.errorCallback(error)
                        })
                    }
                }}

                render={({errors, touched, values, isSubmitting}) => (
                    <Form>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Doctor Name</label>
                                            <Field
                                                type="text"
                                                name="doctorName"
                                                className={'form-control' + (errors.doctorName && touched.doctorName ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="doctorName" component="div"
                                                          className="invalid-feedback"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Mobile Number</label>
                                            <Field
                                                type="text"
                                                name="doctorContactNumber"
                                                maxLength={10}
                                                className={'form-control' + (errors.doctorContactNumber && touched.doctorContactNumber ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="doctorContactNumber" component="div"
                                                          className="invalid-feedback"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <Field
                                                type="email"
                                                name="doctorEmail"
                                                disabled={id}
                                                className={'form-control' + (errors.doctorEmail && touched.doctorEmail ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="doctorEmail" component="div"
                                                          className="invalid-feedback"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <hr/>
                                        <div className="add-more-wrapper">
                                            <FieldArray name="doctorCustoms">
                                                {({insert, remove, push}) => (
                                                    <>
                                                        <div className="row mb-2">
                                                            <div className="col-5">Custom Label</div>
                                                            <div className="col-5">Content</div>
                                                            <div className="col-2">
                                                            </div>
                                                        </div>
                                                        {values.doctorCustoms.length > 0 && values.doctorCustoms.map((custom: any, index: any) => {
                                                            return (
                                                                <>
                                                                    <div className="row" key={index}>
                                                                        <div className="col-5">
                                                                            <div className="form-group form-group-array">
                                                                                <ErrorMessage
                                                                                    name={`doctorCustoms.${index}.label`}
                                                                                    component="div"
                                                                                    className="invalid-feedback-array"
                                                                                />
                                                                                <Field
                                                                                    name={`doctorCustoms.${index}.label`}
                                                                                    placeholder="Enter Custom label"
                                                                                    type="text"
                                                                                    className='form-control'
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`doctorCustoms.${index}.label`}
                                                                                    component="div"
                                                                                    className="invalid-feedback"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            <div className="form-group form-group-array">
                                                                                <ErrorMessage
                                                                                    name={`doctorCustoms.${index}.content`}
                                                                                    component="div"
                                                                                    className="invalid-feedback-array"
                                                                                />
                                                                                <Field
                                                                                    name={`doctorCustoms.${index}.content`}
                                                                                    placeholder="Enter content"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`doctorCustoms.${index}.content`}
                                                                                    component="div"
                                                                                    className="invalid-feedback"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            {values.doctorCustoms.length !== 1 &&
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => remove(index)}
                                                                                className="btn btn-danger"
                                                                            >
                                                                                <FontAwesomeIcon icon={faTrash}
                                                                                                 size="xs"/>
                                                                                <span style={{
                                                                                    marginLeft: '5px',
                                                                                    fontSize: '13px'
                                                                                }}>Remove</span>
                                                                            </button>}
                                                                            {values.doctorCustoms.length - 1 === index &&
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-success"
                                                                                onClick={() => push({
                                                                                    label: "",
                                                                                    content: ""
                                                                                })}
                                                                            >
                                                                                <FontAwesomeIcon icon={faPlus}
                                                                                                 size="xs"/>
                                                                                <span style={{
                                                                                    marginLeft: '5px',
                                                                                    fontSize: '13px'
                                                                                }}>Add</span>
                                                                            </button>}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                )}
                                            </FieldArray>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-center">
                                <button
                                    name="submit"
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isSubmitting}>
                                    {isSubmitting && (
                                        <FontAwesomeIcon className="fa-spin me-2" icon={faSpinner}/>
                                    )}
                                    Submit
                                </button>
                                <Link to="/clinic/amenities" className="btn btn-danger">Cancel</Link>
                            </div>
                        </div>
                    </Form>
                )}

            />
        </>
    );
};
export default ClinicCreateDoctor;
