import * as React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import PreviewFieldComponent from '../../components/PreviewFieldComponent';
import { convertFirestoreDate } from '../../helper/firebasehelper';
import { useFormBuilder } from '../../hooks/formbuilder.hook.';
import { IFormBuilder } from '../../models/FormBuilderType';
import log from 'loglevel';
import { toast } from 'react-toastify';
import { useErrorHandling } from '../../ErrorBoundary';

const AdminClinicFormBuilderDetails = () => {

    let filteredClinicQuestions: any = []
    const finalArrayOfQuestions: IFormBuilder[] = []
    const { triggerError } = useErrorHandling();
    const clinicQuestions = useSelector((state: any) => state.firestore.data.ClinicQuestions);

    const { convertStringToFormbuilderType } = useFormBuilder();

    if (isLoaded(clinicQuestions)) {
       
        try {
            let filteredClinicQuestions = Object.values(clinicQuestions).filter((item: any) => item['isActive'] === true);


            filteredClinicQuestions.forEach((element: any) => {
                let builder: IFormBuilder = convertStringToFormbuilderType(element)
                finalArrayOfQuestions.push(builder)

            });

            finalArrayOfQuestions.sort((q1, q2) => q1.sequence - q2.sequence)
        } catch (error) {
            triggerError(error)
        }

    }


    return (

        <form className="preview-form" >
            <div className="grid-wrapper">
                <div className="grid-header ">



                    <div className="row align-items-center row-height mtb10-mlrauto">

                        <div className="col">
                            Question Title
                        </div>
                        <div  className="col">
                            Question Type
                        </div>
                        <div className="col preview-answers">
                            Answers
                        </div>
                        <div className="col-2">
                            Created On
                        </div>
                        <div className="col-1">
                            Status
                        </div>

                    </div>
                </div>
                {finalArrayOfQuestions && finalArrayOfQuestions.map((item: any) => {

                    return (
                        <div className="row align-items-center row-height mtb10-mlrauto">
                            <div className="col">
                                {item.question}
                            </div>
                            <div  className="col">
                                {item.answerFieldType}
                            </div>
                            <div className="col preview-answers">
                                <PreviewFieldComponent question={item}></PreviewFieldComponent>
                            </div>
                            <div className='col-2'>
                                {convertFirestoreDate(item.createdOn)}
                            </div>
                            <div className="col-1 text-capitalize">

                                {

                                    item.isPublished ? <span className="badge badge-success ml-3">Published</span> : <span className="badge badge-danger ml-3">Pending</span>

                                }
                            </div>

                        </div>
                    )
                })}
            </div>

        </form>
    );
};
export default AdminClinicFormBuilderDetails;

