
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux'
import { createStore, combineReducers, compose } from 'redux'
import {
    ReactReduxFirebaseProvider,
    firebaseReducer
} from 'react-redux-firebase'
import { createFirestoreInstance, reduxFirestore, firestoreReducer } from 'redux-firestore' // <- needed if using firestore
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'



//Clinic Components
import ClinicDashboard from './pages/clinic/Dashboard'
import ClinicCheckin from './pages/clinic/Checkin'
import ClinicCreatePatient from './pages/clinic/CreatePatient'
import ClinicFormBuilder from './pages/clinic/FormBuilder'
import ClinicAmenities from './pages/clinic/ViewAmenities'
import ClinicCreateDoctor from './pages/clinic/CreateDoctor'
import ClinicPatientList from './pages/clinic/PatientList';
import ClinicPatientDetails from './pages/clinic/PatientDetails';
import ClinicDoctorList from './pages/clinic/DoctorList';

//Admin Components
import AdminDashboard from './pages/admin/Dashboard'
import AdminCheckin from './pages/admin/Checkin'
import AdminCreatePatient from './pages/admin/CreatePatient'
import AdminFormBuilder from './pages/admin/FormBuilder'
import AdminCreateDoctor from './pages/admin/CreateDoctor'
import AdminCreateClinic from './pages/admin/CreateClinic'
import AdminViewClinic from './pages/admin/ViewClinic';

import Login from './pages/Login';
import Header from './components/Header'
import Navbar from "./components/Navbar";
import Register from './pages/Register';
import ForgotPassword from './pages/forgotPassword';
import { ToastContainer } from "react-toastify";
import ViewLogs from './pages/ViewLog';
import PrivateRoute from './components/ProtectedRoute'

const NotFound = () => {

    return window.location.href="/404.html"
}

const AppComponent = () => {

    //Redux and Firebase
    const fbConfig = {}

    // react-redux-firebase config
    const rrfConfig = {
        userProfile: 'users',
        useFirestoreForProfile: true
    }; // optional redux-firestore Config Options


    if (!firebase.apps.length) {
        firebase.initializeApp(fbConfig) //If we don't have firebase initialized
    } else {
        firebase.app(); // if already initialized, use that one
    }

    // Add reduxFirestore store enhancer to store creator
    const createStoreWithFirebase = compose(
        reduxFirestore(firebase, rrfConfig), // firebase instance as first argument,rrfConfig as optional second
    )(createStore);


    // Initialize other services on firebase instance
    firebase.firestore()
    //firebase.functions() // <- needed if using httpsCallable

    // // Add firebase to reducers
    const rootReducer = combineReducers({
        firebase: firebaseReducer,
        firestore: firestoreReducer
    })


    // Create store with reducers and initial state
    const initialState = {};
    const store = createStoreWithFirebase(rootReducer, initialState);
    const rrfProps = {
        firebase,
        config: rrfConfig,
        dispatch: store.dispatch,
        createFirestoreInstance
    }
    return (

        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <BrowserRouter>

                    <div className="main-wrapper">
                        <ToastContainer
                            autoClose={3000}
                        />


                        <Header />
                        <Navbar />


                        <div className="page-wrapper">
                            <div className="content container-fluid">

                                <Routes>

                                    <Route path="/" >
                                        <Route index={true} element={<Login />} />
                                        <Route index={false} path="login" element={<Login />} />
                                        <Route index={false} path="ViewLogs" element={<ViewLogs />} />
                                        <Route index={false} path="register" element={<Register />} />
                                        <Route index={false} path="forgotPassword" element={<ForgotPassword />} />
                                    </Route>
                                    <Route path="clinic" >
                                        <Route index={true} element={<PrivateRoute role='Clinic'><ClinicDashboard /></PrivateRoute>} />

                                        <Route
                                            path="dashboard"
                                            element={
                                                <PrivateRoute index={false} role='Clinic'>
                                                    <ClinicDashboard />
                                                </PrivateRoute>
                                            }

                                        ></Route>

                                        <Route index={false} path="checkin" element={<PrivateRoute role='Clinic'><ClinicCheckin /></PrivateRoute>} />
                                        <Route index={false} path="create-patient" element={<PrivateRoute role='Clinic'><ClinicCreatePatient /></PrivateRoute>} />
                                        <Route index={false} path="form-builder" element={<PrivateRoute role='Clinic'><ClinicFormBuilder /></PrivateRoute>} />
                                        <Route index={false} path="amenities" element={<PrivateRoute role='Clinic'><ClinicAmenities /></PrivateRoute>} />
                                        <Route index={false} path="create-doctor" element={<PrivateRoute role='Clinic'><ClinicCreateDoctor /></PrivateRoute>} />
                                        <Route index={false} path="patient-list" element={<PrivateRoute role='Clinic'><ClinicPatientList /></PrivateRoute>} />
                                        <Route index={false} path="patient-details/:patientId" element={<PrivateRoute role='Clinic'><ClinicPatientDetails /></PrivateRoute>} />
                                        {/* <Route index={false} path="doctor-list" element={<PrivateRoute role='Clinic'><ClinicDoctorList /></PrivateRoute>} /> */}
                                    </Route>


                                    <Route path="admin" >
                                        <Route index={true} element={<PrivateRoute role='Admin'><AdminDashboard /></PrivateRoute>} />

                                        <Route
                                            path="dashboard"
                                            element={
                                                <PrivateRoute index={false} role='Admin'>
                                                    <AdminDashboard />
                                                </PrivateRoute>
                                            }

                                        ></Route>

                                        <Route index={false} role='Admin' path="checkin" element={<AdminCheckin />} />
                                        <Route index={false} role='Admin' path="create-patient" element={<AdminCreatePatient />} />
                                        <Route index={false} role='Admin' path="form-builder" element={<AdminFormBuilder />} />
                                        <Route index={false} role='Admin' path="create-doctor" element={<AdminCreateDoctor />} />
                                        <Route index={false} role='Admin' path="create-clinic" element={<AdminCreateClinic />} />
                                        <Route index={false} role='Admin' path="view-clinic/:id" element={<AdminViewClinic />} />
                                        <Route index={false} role='Admin' path="clinic-patient-list" element={<AdminViewClinic />} />
                                        <Route index={false} role='Admin' path="clinic-form-builder-details" element={<AdminViewClinic />} />
                                        <Route index={false} role='Admin' path="clinic-doctors" element={<AdminViewClinic />} />
                                        <Route index={false} role='Admin' path="amenities" element={<AdminViewClinic />} />
                                        <Route index={false} role='Admin' path="patient-details/:clinicIdFromAdmin/:patientId" element={<ClinicPatientDetails />} />
                                    </Route>

                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                            </div>
                        </div>
                    </div>

                </BrowserRouter>
            </ReactReduxFirebaseProvider>

        </Provider>

    )
}
export default AppComponent;
