import * as React from 'react';
import {Link, useNavigate} from "react-router-dom";
import Logo from '../assets/images/logo-icon.svg';
import {useLogin} from "../hooks/login.hook";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {useClinic} from "../hooks/clinic.hook"
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from 'react-toastify';

const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

const Login = () => {
    const navigate = useNavigate();
    const {userLogin, userSignOut} = useLogin()
    const {getClinicIdByUser} = useClinic()

    const successCallBack = (data: any) => {
        sessionStorage.setItem('email', data.email);
        console.info("Successfully logged in")
        if (data.admin) {
            sessionStorage.setItem('isAdmin', 'true');
            navigate("/admin/dashboard");
        } else {
            getClinicIdByUser(data.email).then(data => {
                if (data.length === 0) {
                    toast.error('No clinic is associated with this user')
                    userSignOut()
                    return;
                }
                if (data.isApproved && data.isActive && !data.isDeleted) {
                    //Setting the clinic id into localstorage.
                    sessionStorage.setItem('clinicid', data.id);
                    sessionStorage.setItem('isAdmin', 'false');


                    navigate("/clinic/dashboard")
                } else {
                    toast.error('Please Contact Administrator - Clinic is Inactive')
                    userSignOut()
                }
            })
        }
    }
    return (
        <div className="login-wrapper">
            <div className="loginbox">
                <div className="login-left">
                    <img className="img-fluid" src={Logo} alt="Logo"/>
                </div>
                <div className="login-right">
                    <div className="login-right-wrap">
                        <h1>Login</h1>
                        <p className="account-subtitle">Access to our dashboard</p>
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            validationSchema={LoginSchema}
                            onSubmit={(values, actions) => {
                                setTimeout(() => {
                                    actions.setSubmitting(false);
                                }, 1000);

                                userLogin(values.email, values.password, successCallBack)
                            }}
                        >
                            {({errors, touched, isSubmitting}) => (
                                <Form>
                                    <div className="form-group">
                                        <Field
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                                        />
                                        <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                                    </div>
                                    <div className="form-group">
                                        <Field
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                            className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                                        />
                                        <ErrorMessage name="password" component="div" className="invalid-feedback"/>
                                    </div>
                                    <div className="form-group">
                                        <button
                                            className="btn btn-primary btn-block w-100"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting && (
                                                <FontAwesomeIcon className="fa-spin me-2" icon={faSpinner}/>
                                            )}
                                            Login
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <div className="text-center forgotpass">
                            <Link to="/forgotPassword">
                                <a>Forgot Password?</a>
                            </Link>
                        </div>
                        <div className="login-or">
                            <span className="or-line"></span>
                            <span className="span-or">or</span>
                        </div>
                        <div className="text-center dont-have">
                            Don’t have an account?
                            <Link to="/register"> <a>Register</a></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Login;
