import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

const Header = ({ headers, onSorting }) => {
    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("asc");

    const onSortingChange = (field) => {
        const order = field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
        setSortingField(field);
        setSortingOrder(order);
        onSorting(field, order);
    };

    return (
        <div className="grid-header">
            <div className="row">
                {headers.map(({ name, field, sortable, colsize }) => (
                    <div className={`${colsize ? 'col-'+colsize : 'col' + ' '} ${name.toLowerCase().replace(' ', '')}`}
                        key={name}
                        onClick={() =>
                            sortable ? onSortingChange(field) : null
                        }
                    >
                        {name}

                        {sortingField && sortingField === field && (
                            <FontAwesomeIcon
                                icon={
                                    sortingOrder === "asc"
                                        ? faArrowDown
                                        : faArrowUp
                                }
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Header;
