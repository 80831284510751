import firebase from 'firebase'
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage'


 
let firebaseConfig;

if(process.env.REACT_APP_FIREBASE_FILE==='dev'){
  firebaseConfig = {
    apiKey: "AIzaSyCmiohW83ZmJlbcTGqoM2qIqNwvjRBROrU",
    authDomain: "my-opioid-recovery.firebaseapp.com",
    projectId: "my-opioid-recovery",
    storageBucket: "my-opioid-recovery.appspot.com",
    messagingSenderId: "263677412637",
    appId: "1:263677412637:web:3166e976ac2a1a4027cdf4",
    measurementId: "G-ZXF1NB8LWZ"
  };
}
else if(process.env.REACT_APP_FIREBASE_FILE==='qa')
{
  firebaseConfig = {
    apiKey: "AIzaSyCmiohW83ZmJlbcTGqoM2qIqNwvjRBROrU",
    authDomain: "my-opioid-recovery.firebaseapp.com",
    projectId: "my-opioid-recovery",
    storageBucket: "my-opioid-recovery.appspot.com",
    messagingSenderId: "263677412637",
    appId: "1:263677412637:web:3166e976ac2a1a4027cdf4",
    measurementId: "G-ZXF1NB8LWZ"
  };
}
else{
  firebaseConfig = {
    apiKey: "AIzaSyCmiohW83ZmJlbcTGqoM2qIqNwvjRBROrU",
    authDomain: "my-opioid-recovery.firebaseapp.com",
    projectId: "my-opioid-recovery",
    storageBucket: "my-opioid-recovery.appspot.com",
    messagingSenderId: "263677412637",
    appId: "1:263677412637:web:3166e976ac2a1a4027cdf4",
    measurementId: "G-ZXF1NB8LWZ"
  };
  
}

  /* if(process.env.REACT_APP_FIREBASE_FILE==='dev'){
    firebaseConfig = {
      apiKey: "AIzaSyC1dh6qn8k8w-c2fxNF5FcSAI773sPa0Kk",
      authDomain: "dev-opioidrecovery.firebaseapp.com",
      projectId: "dev-opioidrecovery",
      storageBucket: "dev-opioidrecovery.appspot.com",
      messagingSenderId: "660575258620",
      appId: "1:660575258620:web:d7a4fed1dfd39640751479",
      measurementId: "G-144XQZ3XJM"
    };
  }
  else if(process.env.REACT_APP_FIREBASE_FILE==='qa')
  {

    firebaseConfig = {
        apiKey: "AIzaSyDHFfwujLNzZgAH7207vEErfK9O6QvpW64",
        authDomain: "qa-opioidrecovery.firebaseapp.com",
        projectId: "qa-opioidrecovery",
        storageBucket: "qa-opioidrecovery.appspot.com",
        messagingSenderId: "633203802123",
        appId: "1:633203802123:web:0144862718d9a2e4d6470e",
        measurementId: "G-WLQT5M99TP"
      };
  }
  else{
     firebaseConfig = {
      apiKey: "AIzaSyCYmPSw_RanirD_i1_rq0CnimguDc9S-QI",
      authDomain: "uat-opioidrecovery.firebaseapp.com",
      projectId: "uat-opioidrecovery",
      storageBucket: "uat-opioidrecovery.appspot.com",
      messagingSenderId: "338920181086",
      appId: "1:338920181086:web:5b242d125c2228d97f93cf"
    };
    
  } */
 
 
const Firebase = firebase.initializeApp(firebaseConfig);
export const FirebaseSecondary = firebase.initializeApp(firebaseConfig,"SecondInstance");

export const db = firebase.firestore();
export const storage = firebase.storage();
export default Firebase;
