import React, { Fragment } from "react";

const Options = ({ field,field_id}:{field:any,field_id:string}) => {
  return (
    <div>
       
      {field.map((option:any, index:number) => {
        return (
          <Fragment key={option}>
            <label htmlFor={option}>
              <input
                type="radio"
                id={option}
                name={field_id}
               
            
               
              />
              {option}
            </label>
             
          </Fragment>
        );
      })}
    </div>
  );
};

export default Options;
