import firebase from 'firebase';
import '../config/firebase'
import { FirestoreClient } from '../library/FirestoreClient';
import {TableNames} from "../helper/TableNames";
const db = firebase.firestore();

export const useRecordsCount = () => {

    const clinicCount = async () => {

        const rowsCount = await FirestoreClient.getRowCountByField(TableNames.Clinics, 'isDeleted', false).then((count) => {
            return count;
        })
        return rowsCount;


    }
    const doctorsCount = async () => {
        const rowsCount = await FirestoreClient.getActiveRowCount(TableNames.Doctors).then((count) => {
            return count;
        })
        return rowsCount;

    }
    const doctorsCountByClinic = async () => {
        const rowsCount = await FirestoreClient.getRowCountByField(TableNames.Doctors,'clinic_id','1').then((count) => {
            return count;
        })
        return rowsCount;

    }
    const patientsCount = async () => {

        const rowsCount = await FirestoreClient.getRowCount(TableNames.Patients).then((count) => {
            return count;
        })
        return rowsCount;

    }
    const doctorsForClinicCount = async () => {
        const rowsCount = await FirestoreClient.getRowCount(TableNames.Doctors).then((count) => {
            return count;
        })
        return rowsCount;

    }



    return {
        clinicCount,
        doctorsCount,
        patientsCount
    }

}
