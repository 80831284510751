import * as React from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {  faUser } from '@fortawesome/free-solid-svg-icons';
import logo from "../assets/images/logo-icon.svg";
import logoicon from "../assets/images/logo-icon.svg";
import {getFirebase} from "react-redux-firebase";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLogin } from '../hooks/login.hook';

const Header = () => {
    const firebase = getFirebase();
    const navigate = useNavigate();
    const {userSignOut}= useLogin()
    const handlesidebar = () => {
        document.body.classList.toggle('mini-sidebar');
    }
    const isAdmin = sessionStorage.getItem('isAdmin')
    const logoLink = isAdmin == 'true' ? '/admin/dashboard' : '/clinic/dashboard'
    const auth = useSelector((state: any) => state.firebase.auth);


    const logout = ()=> {
        sessionStorage.removeItem('id');
        sessionStorage.removeItem('isAdmin');
        sessionStorage.clear();
        userSignOut();
        navigate("/");
    }

    const location = useLocation()
    const exclusionArray = [
        "/",
        "/login",
        "/register",
        "/viewlogs",
        "/forgotPassword",
        "/404",
        "/500",
    ];
    if (exclusionArray.indexOf(location.pathname) >= 0) {
        return "";
    }
    return (
        <div className="header">
            <div className="header-left">
                <Link to={logoLink} className="logo">
                    <img src={logoicon} alt="Logo"/>
                </Link>
                {/*<Link to={logoLink} className="logo logo-small">
                    <img src={logoicon} alt="Logo" width="30" height="30"/>
                </Link>*/}
            </div>
            <a id="toggle_btn" onClick={handlesidebar}>
                <i className="fe fe-text-align-left"/>
            </a>
            {/* Mobile Menu Toggle */}
            <a href="#0" className="mobile_btn" id="mobile_btn">
                <i className="fa fa-bars"/>
            </a>

            <ul className="nav user-menu ">
                <li className="nav-item dropdown ">
                <FontAwesomeIcon color={'#80c342'} title='up' cursor='hand' icon={faUser} className="me-2" />



                {auth && auth.email}
                </li>
                <li className="nav-item dropdown noti-dropdown">
                    <div className="notify dropdown">
                     <button className="nav-link dropdown-toggle btn btn-outline-primary" onClick={logout}>
                            <i className="fe fe-logout"></i>
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default Header
