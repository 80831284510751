import * as React from 'react';

import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {statesList} from "../../constants";
import moment from "moment";
import {FirestoreClient} from "../../library/FirestoreClient";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {TableNames} from "../../helper/TableNames";
import {IPatient, PatientType} from "../../models/PatientType";
import {IAddress, IClinic} from "../../models/ClinicType";
import {currentDate, randomId} from "../../helper/firebasehelper";
import {useFormBuilder} from "../../hooks/formbuilder.hook.";
import {useEffect, useState} from "react";
import firebase from "firebase";

const ClinicPatientSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required')
        .matches(
            /^[a-zA-Z0-9 ]+$/,
            "First Name is invalid"
        ),
    middleName: Yup.string()
        .matches(
            /^[a-zA-Z0-9 ]+$/,
            "Middle Name is invalid"
        ),
    lastName: Yup.string().required('Last name is required')
        .matches(
            /^[a-zA-Z0-9 ]+$/,
            "Last Name is invalid"
        ),
    DOB: Yup.date().required('DOB is required').max(moment().format('YYYY-MM-DD')),
    email: Yup.string().email('Invalid email'),
    gender: Yup.string().required('Select gender'),
    Address1: Yup.string().required('Address is required'),
    City: Yup.string().required('City is required'),
    State: Yup.string().required('State is required'),
    Zip: Yup.string()
        .required('Zip is required')
        .min(5, 'Zip should be minimum 5 digits')
        .matches(
            /^\d{5}(?:[-\s]\d{4})?$/, "Zip is invalid"
        ),
    phone: Yup.string()
        .required('Mobile Number is required')
        .min(10, 'Mobile Number should be 10 digits')
        .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            "Phone is invalid"
        )
        .typeError('Phone must be in digits'),
});

const ClinicCreatePatient = () => {
    const [clinicQuestions, setClinicQuestions] = useState([])
    const {getQuestionsFromClinics} = useFormBuilder()

    const clinicId = sessionStorage.getItem('clinicid')

    const location = useLocation()
    const id = location?.state?.id
    const pageTitle = id ? 'Update Patient' : 'Create Patient'

    const toastMessage = id ? 'Updated Patient Successfully' : 'Created Patient Successfully'

    const navigate = useNavigate()

    const convertPatientObjectToStringObject = (patient: IPatient) => {
        const stringObject = {
            'firstName': patient.firstName,
            'lastName': patient.lastName,
            'middleName': patient.middleName,
            'DOB': patient.DOB,
            'gender': patient.gender,
            'phone': patient.phone,
            'email': patient.email,
            'address': patient.address,
            'createdOn': patient.createdOn,
            'isActive': patient.isActive,
            'isDeleted': false,
            'secretKey': patient.secretKey,
        }
        return stringObject;
    }


    const successCallback = (data: any) => {
        toast.success(toastMessage)
        setTimeout(() => {
            window.location.href = "/clinic/dashboard"

        }, 1000)
    }
    useEffect(() => {
            getQuestionsFromClinics(clinicId as string).then((results: any) => {
                setClinicQuestions(results)
            })
        },
        [])
    return (
        <>
            <div className="page-header">
                <h3 className="page-title">{pageTitle}</h3>
            </div>
            <Formik
                initialValues={{
                    firstName: location?.state?.firstName ? location?.state?.firstName : '',
                    lastName: location?.state?.lastName ? location?.state?.lastName : '',
                    middleName: location?.state?.middleName ? location?.state?.middleName : '',
                    DOB: location?.state?.DOB ? location?.state?.DOB : '',
                    email: location?.state?.email ? location?.state?.email : '',
                    phone: location?.state?.phone ? location?.state?.phone : '',
                    gender: location?.state?.gender ? location?.state?.gender : '',

                    Address1: location?.state?.address.address1 ? location?.state?.address.address1 : '',
                    Address2: location?.state?.address.address2 ? location?.state?.address.address2 : '',
                    City: location?.state?.address.city ? location?.state?.address.city : '',
                    State: location?.state?.address.state ? location?.state?.address.state : '',
                    Zip: location?.state?.address.zip ? location?.state?.address.zip : '',

                    isActive: location?.state?.isActive ? location?.state?.isActive : true,
                    isDeleted: location?.state?.isDeleted ? location?.state?.isDeleted : false,
                    createdOn: location?.state?.createdOn ? location?.state?.createdOn : currentDate,
                    secretKey: location?.state?.secretKey ? location?.state?.secretKey : randomId()

                }}
                validationSchema={ClinicPatientSchema}
                onSubmit={(values, actions) => {

                    const addressObject = {
                        'address1' : values.Address1,
                        'address2' : values.Address2,
                        'city' : values.City,
                        'state' : values.State,
                        'zip' : values.Zip,
                    }

                    const dataObject: PatientType = new PatientType(
                        id ? id : '',
                        values.firstName,
                        values.lastName,
                        values.middleName,
                        values.DOB,
                        values.gender,
                        values.phone,
                        values.email,
                        addressObject,
                        values.createdOn,
                        values.secretKey,
                    )
                    dataObject.isActive = values.isActive
                    dataObject.isDeleted = values.isDeleted

                    //const objectJson = JSON.parse(JSON.stringify(dataObject));
                    const objectJson = convertPatientObjectToStringObject(dataObject);

                    const objectClinicQuestions = JSON.parse(JSON.stringify(clinicQuestions));

                    if (id) {
                        FirestoreClient.updateSubCollection(
                            TableNames.Clinics,
                            clinicId,
                            TableNames.ClinicPatients,
                            objectJson,
                            id
                        ).then((res) => {
                            successCallback(res)
                        })
                    } else {
                        FirestoreClient.save(TableNames.Patients, objectJson)
                            .then((id: any) => {

                            })
                            .catch((error) => {
                                return error
                            });
                        FirestoreClient.saveSubCollection(
                            TableNames.Clinics,
                            clinicId,
                            TableNames.ClinicPatients,
                            objectJson
                        ).then((id) => {
                            FirestoreClient.updateSubCollection(
                                TableNames.Clinics,
                                clinicId,
                                TableNames.ClinicPatients,
                                {clinicQuestions: objectClinicQuestions},
                                id).then((id) => {
                                return id
                            }).catch((err) => {
                                console.log(err)
                            })
                            return id
                        }).then((response) => {
                            if (response != 'error') {
                                successCallback(response)
                            } else {
                                toast.error('Patient already exists')
                                actions.setSubmitting(false)
                            }

                        }).catch((error) => {
                            actions.setSubmitting(false)
                            FirestoreClient.errorCallback(error)
                        })
                    }
                }
                }
            >
                {({errors, status, touched, isSubmitting}) => (
                    <Form>
                        <div className="card">
                            <div className="card-body">

                                <h5>Personal Information</h5>
                                <hr/>
                                <div className="row mb-4">
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <Field
                                                type="text"
                                                name="firstName"
                                                className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="firstName" component="div"
                                                          className="invalid-feedback"/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Middle Name</label>
                                            <Field
                                                type="text"
                                                name="middleName"
                                                className={'form-control' + (errors.middleName && touched.middleName ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="middleName" component="div"
                                                          className="invalid-feedback"/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <Field
                                                type="text"
                                                name="lastName"
                                                className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="lastName" component="div" className="invalid-feedback"/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <Field
                                                type="email"
                                                name="email"
                                                className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Mobile Number</label>
                                            <Field
                                                type="text"
                                                name="phone"
                                                maxLength={10}
                                                className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="phone" component="div" className="invalid-feedback"/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>DOB</label>
                                            <Field
                                                type="date"
                                                name="DOB"
                                                className={'form-control' + (errors.DOB && touched.DOB ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="DOB" component="div" className="invalid-feedback"/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Gender</label>
                                            <div className="d-flex flex-wrap">
                                                <div className="d-flex align-items-center mr-10 justify-content-center">
                                                    <Field type="radio" id="genderMale" name="gender"
                                                           className={'form-check-input mr-5' + (errors.gender && touched.gender ? ' is-invalid' : '')}
                                                           value="Male"/>
                                                    <label htmlFor="genderMale"
                                                           className="form-check-label ml-10 mt-5p">Male</label>
                                                </div>
                                                <div className="d-flex align-items-center ml-10 justify-content-center">
                                                    <Field type="radio" id="genderFemale" name="gender"
                                                           className={'form-check-input mr-5' + (errors.gender && touched.gender ? ' is-invalid' : '')}
                                                           value="Female"/>
                                                    <label htmlFor="genderFemale"
                                                           className="form-check-label ml-10 mt-5p">Female</label>
                                                </div>
                                            </div>
                                            <ErrorMessage name="gender" component="div"
                                                          className="invalid-feedback d-block"/>
                                        </div>
                                    </div>
                                </div>
                                <h5>Address</h5>
                                <hr/>
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Address 1</label>
                                            <Field
                                                type="text"
                                                name="Address1"
                                                className={'form-control' + (errors.Address1 && touched.Address1 ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="Address1" component="div" className="invalid-feedback"/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Address 2</label>
                                            <Field
                                                type="text"
                                                name="Address2"
                                                className={'form-control' + (errors.Address2 && touched.Address2 ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="Address2" component="div" className="invalid-feedback"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>City</label>
                                            <Field
                                                type="text"
                                                name="City"
                                                className={'form-control' + (errors.City && touched.City ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="City" component="div" className="invalid-feedback"/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>State</label>
                                            <Field
                                                component="select"
                                                name="State"
                                                className={'form-select' + (errors.State && touched.State ? ' is-invalid' : '')}
                                            >
                                                <option>Select State</option>
                                                {statesList.map(({code, name}, index) => (
                                                    <option value={code}>{name}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="State" component="div" className="invalid-feedback"/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label>Zip</label>
                                            <Field
                                                type="text"
                                                name="Zip"
                                                maxLength={10}
                                                className={'form-control' + (errors.Zip && touched.Zip ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="Zip" component="div" className="invalid-feedback"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-center">
                                <button
                                    name="submit"
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isSubmitting}>
                                    {isSubmitting && (
                                        <FontAwesomeIcon className="fa-spin me-2" icon={faSpinner}/>
                                    )}
                                    Submit
                                </button>
                                <Link to="/clinic/dashboard" className="btn btn-danger">Cancel</Link>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};
export default ClinicCreatePatient;
