import * as React from 'react';
import PatientsList from "./dashboard/PatientsList";
import {isEmpty, isLoaded, useFirestoreConnect} from "react-redux-firebase";
import {TableNames} from "../../helper/TableNames";
import {useSelector} from "react-redux";
import Loader from "../../components/Loader";
import {usePatient} from "../../hooks/patient.hook";
import {Link} from "react-router-dom";
import { faRust } from '@fortawesome/free-brands-svg-icons';

const ClinicPatientList = (props: any) => {
    const isAdmin = sessionStorage.getItem('isAdmin')
    const {deletePatient, editPatient, statusChangePatient} = usePatient()
    useFirestoreConnect({
        collection: TableNames.Clinics,
        doc: props.clinicId,
        subcollections: [{collection: TableNames.ClinicPatients}],
        storeAs: TableNames.ClinicPatients,
        where: ['isDeleted', '==', false],
        // orderBy:[            
        //     ['createdOn', 'desc']
        //   ] ,
       
    })

    let patientList = useSelector((state: any) => state.firestore.data.ClinicPatients);
    if (patientList) {
        patientList = Object.keys(patientList).map((k) => patientList[k])
         
     }

     React.useEffect(()=>{

     },[patientList])
    return (
        <>
            <div className="card">
                {isAdmin == 'false' && (
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="card-title mb-0">Patients List</h5>
                    <Link to="/clinic/create-patient" className="btn btn-primary">Create Patient</Link>
                </div>
                )}
                <div className="card-body p-0">


                    {!isLoaded(patientList) && (
                        <Loader/>
                    )}
                    {isEmpty(patientList) && isLoaded(patientList) && (
                        <>
                            <p className="text-center my-5">No Patients Available</p>
                        </>
                    )}
                    
                    {patientList && (
                        <PatientsList
                            patientList={patientList}
                            deletePatient={deletePatient}
                            editPatient={editPatient}
                            statusChangePatient={statusChangePatient}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
export default ClinicPatientList;
