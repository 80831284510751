import * as React from 'react';

const ClinicAmenities = (props: any) => {
    if (props?.clinic?.ClinicAmenities) {
        return (
            <>
                <div className="card mb-0">
                    <div className="card-body">
                        <div className="row">

                            <div className="col-6">
                                <div className="row">
                                    <div className="col-4"><strong>Inventory Number</strong></div>
                                    <div className="col-8">{props?.clinic?.ClinicAmenities.inventoryNumber}</div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-4"><strong>No. Of Beds</strong></div>
                                    <div className="col-8">{props?.clinic?.ClinicAmenities.noOfBeds}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-wrapper">
                    <div className="grid-header">
                        <div className="row">
                            <div className="col-1">Sno</div>
                            <div className="col-3">Label</div>
                            <div className="col-8">Content</div>
                        </div>
                    </div>
                    {props?.clinic?.ClinicAmenities.customs.length > 0 &&
                    props?.clinic?.ClinicAmenities.customs.map((custom: any, index: any) => {
                            return (
                                <>
                                    <div className="grid-block" key={index}>
                                        <div className="row">
                                            <div className="col-1">{index+1}</div>
                                            <div className="col-3">{custom.label}</div>
                                            <div className="col-8">{custom.content}</div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    )}
                </div>
            </>
        );
    } else {
        return <>No amenities available</>
    }
};
export default ClinicAmenities;

