 
import React from 'react'
import Options from '../pages/clinic/formbuilder/Options'
import Field from '../pages/clinic/formbuilder/Field'
import CheckBox from "../pages/clinic/formbuilder/CheckBox";

const PreviewFieldComponent = (props: any) => {
    const question = props.question

    switch (question.answerFieldType) {
        case "textarea":
            return (

                <Field
                    key={question.id}
                    field={question}
                    type='textarea'
                />
            );
        case "select":
            return (


                <Options key={question.id}
                    field={question.answerCollection}
                    field_id={question.id}
                ></Options>
            );
        case "checkbox":
            return (

                <CheckBox key={question.id}
                    field={question.answerCollection}
                    field_id={question.id}
                ></CheckBox>
            );
        default:
            return (
                <Field
                    key={question.id}
                    field={question}
                    type='textbox'
                />
            );
    }
}

export default PreviewFieldComponent