import * as React from 'react';
import { useEffect, useState, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from "react-router-dom";
import { useCommon } from "../../../hooks/common.hooks";
import moment from "moment";
import { TableHeader, Pagination, Search } from "../../../components/DataTable";
import Loader from "../../../components/Loader";
import {confirmAlert} from "react-confirm-alert";
import {TableNames} from "../../../helper/TableNames";
import {convertFirestoreDate, convertFirestoreTimestampToDate} from "../../../helper/firebasehelper";

const ClinicsList = (props: any) => {
    let listData = props.clinicsList

    let [data, setData] = useState( props.clinicsList)


 let isUpdated=false;

    const deleteConfirm = (item: any) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h3>Are you sure?</h3>
                        <p>You want to delete</p>
                        <button className="btn btn-outline-danger" onClick={onClose}>No</button>
                        <button className="btn btn-primary"
                            onClick={() => {
                                props.deleteClinic(item);
                                onClose()



                            }}
                        >
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });
    }

    const approveConfirm = (item: any) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h3>Are you sure?</h3>
                        <p>You want to approve</p>
                        <button className="btn btn-outline-danger" onClick={onClose}>No</button>
                        <button className="btn btn-primary"
                            onClick={() => {
                                props.approveClinic(item);
                                onClose()
                            }}
                        >
                            Yes, Approve it!
                        </button>
                    </div>
                );
            }
        });
    }

    const statusChangeConfirm = (item: any) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h3>Are you sure?</h3>
                        <p>You want to change status</p>
                        <button className="btn btn-outline-danger" onClick={onClose}>No</button>
                        <button className="btn btn-primary"
                            onClick={() => {
                                props.statusChangeClinic(item);
                                onClose()
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            }
        });
    }

    const headers = [
        {name: "S.No", field: "clinicName", colsize: '1', sortable: false},
        { name: "Clinic Name", field: "clinicName", colsize: '2', sortable: true },
        { name: "Contact", field: "clinicContact", colsize: '2', sortable: true },
        { name: "Email", field: "clinicEmail", colsize: '2', sortable: true },
        { name: "Approval", field: "isApproved", colsize: '1', sortable: false },
        { name: "Created On", field: "createdOn", colsize: '1', sortable: true },
        { name: "Status", field: "isActive", colsize: '1', sortable: false },
        { name: "Actions", field: "isActive", colsize: '', sortable: false },
    ];

    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 20;

    const searchQuery = (item: any) => {
        return item.clinicName.toLowerCase().includes(search.toLowerCase())
            || item.clinicEmail.toLowerCase().includes(search.toLowerCase())
            || item.clinicContact.toLowerCase().includes(search.toLowerCase())
    }

    const compareQuery = (a: any, b: any) => {
        const reversed = sorting.order === "asc" ? 1 : -1;
        return reversed * a[sorting.field].localeCompare(b[sorting.field]);
    }

    // listData = useMemo(() => {
    //     if (search) {
    //         listData = listData.filter(searchQuery);
    //     }
    //     setTotalItems(listData.length);

    //     if (sorting.field) {
    //         listData = listData.sort(compareQuery)
    //     }

    //     //Current Page slice
    //     return listData.slice(
    //         (currentPage - 1) * ITEMS_PER_PAGE,
    //         (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    //     );

    //     console.log("usememo")
    // }, [ currentPage, search, sorting]);

    useEffect(() => {

        let tempData = [...listData]
          if (search) {
            tempData = tempData.filter(searchQuery);
            setTotalItems(0);
        }
        else {
            setTotalItems(tempData.length);
            tempData = tempData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        }

        if (sorting.field) {
            tempData = tempData.sort(compareQuery)
        }

        setData(tempData)

    }, [search, currentPage])


    useEffect(()=>{
        let tempData = [...listData]

        setData(tempData)

    },[listData])
    return (
        <>
            <div className="row justify-content-end mx-0 my-3">
                <div className="col-md-12 d-flex justify-content-end">
                    <Search
                        onSearch={(value: any) => {

                            setSearch(value);
                        }}
                    />
                </div>
            </div>
            <div className="grid-wrapper">
                <TableHeader
                    headers={headers}
                    onSorting={(field: any, order: any) =>
                        setSorting({ field, order })
                    }
                />

                {data.map((item: any, index: any) => (
                  item &&   <div className="grid-block" key={index}>
                        <div className="row align-items-center">
                            <div className="col-1"> {index + 1} </div>
                            <div className="col-2"> {item['clinicName']} </div>
                            <div className="col-2">{item['clinicContact']}</div>
                            <div className="col-2">{item['clinicEmail']}</div>
                            <div className="col-1">
                                {item['isApproved'] ? (
                                    <span className="badge badge-success cursor-pointer">Approved</span>
                                ) : (
                                    <span className="badge badge-danger cursor-pointer"
                                        onClick={() => {
                                            approveConfirm(item)
                                        }}
                                    >
                                        Approve Now
                                    </span>
                                )}

                            </div>
                            <div className="col-1">{convertFirestoreTimestampToDate(item['createdOn'])}</div>
                            <div className="col-1">
                                {item['isActive'] ? (
                                    <span className="badge badge-success cursor-pointer"
                                        onClick={() => {
                                            statusChangeConfirm(item)
                                        }}
                                    >
                                        Active
                                    </span>
                                ) : (
                                    <span className="badge badge-danger cursor-pointer"
                                        onClick={() => {
                                            statusChangeConfirm(item)
                                        }}
                                    >
                                        Inactive
                                    </span>
                                )}
                            </div>
                            <div className="col">
                                <button type="button" className="btn btn-sm btn-outline-secondary"
                                    onClick={() => {
                                        props.editClinic(item)
                                    }
                                    }>
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </button>
                                <button disabled={item['isApproved']} type="button"
                                    className="btn btn-sm btn-outline-secondary"
                                    onClick={() => {
                                        if (!item['isApproved'])
                                            deleteConfirm(item)
                                    }
                                    }>
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                                <button className="btn btn-sm btn-outline-secondary"
                                    onClick={() => {
                                        props.viewClinic(item)
                                    }
                                    }>
                                    <FontAwesomeIcon icon={faEye} />
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
           { !search && <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page: any) => setCurrentPage(page)}
            />}
        </>
    );
};
export default ClinicsList;
