import * as React from 'react';
import {useState} from "react";
import Amenities from "./Amenities";
import ClinicDoctorList from "./DoctorList";
import {useFirestoreConnect} from "react-redux-firebase";
import {TableNames} from "../../helper/TableNames";

const ViewClinicAmenities = () => {
    const clinicId:any = sessionStorage.getItem('clinicid')
    const [activePage, setActivePage] = useState('doctors')
    const openPage = (pageName: any) => {
        setActivePage(pageName)
    }

    useFirestoreConnect({
        collection: TableNames.Clinics,
        doc: clinicId,
        subcollections: [{collection: TableNames.ClinicDoctors}],
        where: ['isDeleted', '==', false],
        storeAs: TableNames.ClinicDoctors

    })


    return (
        <>
            <div className="page-header">
                <h3 className="page-title">Amenities</h3>
            </div>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <button className={activePage === 'doctors' ? 'nav-link active' : 'nav-link text-dark'}
                            onClick={() => {
                                openPage('doctors')
                            }}>
                        Doctors
                    </button>
                </li>
                <li className="nav-item">
                    <button className={activePage === 'amenities' ? 'nav-link active' : 'nav-link text-dark'}
                            onClick={() => {
                                openPage('amenities')
                            }}>
                        Amenities
                    </button>
                </li>
            </ul>
            <div className="tab-content">
                {activePage == 'doctors' && (
                    <ClinicDoctorList
                        clinicId={clinicId}/>
                )}
                {activePage == 'amenities' && (
                    <Amenities/>
                )}

            </div>

        </>
    );
};
export default ViewClinicAmenities;
