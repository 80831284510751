import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faBed, faChevronLeft, faEye, faUserInjured, faUserMd} from "@fortawesome/free-solid-svg-icons";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import ClinicInfo from "../../components/ClinicInfo";
import AdminClinicFormBuilderDetails from "./ClinicFormBuilderDetails";
import Amenities from "./Amenities";
import PatientsList from "../clinic/dashboard/PatientsList";
import {useEffect, useState} from "react";
import {FirestoreClient} from "../../library/FirestoreClient";
import Loader from "../../components/Loader";
import DoctorsList from "../clinic/dashboard/DoctorsList";
import {TableNames} from "../../helper/TableNames";
import {isLoaded, useFirestoreConnect} from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import ClinicDoctorList from "../clinic/DoctorList";
import ClinicPatientList from "../clinic/PatientList";
import ViewAmenities from "./ViewAmenities";

const AdminViewClinic = (props: any) => {
    const {id} = useParams()
    let clinicInfo: any = []
    const location = useLocation()
    const navigate = useNavigate()

    const [activePage, setActivePage] = useState('clinic-details')
    const [loading, setLoading] = useState(false);

    useFirestoreConnect({
        collection: TableNames.Clinics,
        storeAs: "Clinic",
        where: ['id', '==', id],
    });
    let clinicDetails = useSelector((state: any) => state.firestore.data['Clinic']);
    if (isLoaded(clinicDetails)) {
        clinicInfo = Object.values(clinicDetails).filter((item: any) => item['id'] === id);
        clinicInfo = clinicInfo[0]
    }

    //Getting clincs\clinicpatients state
    useFirestoreConnect({
        collection: TableNames.Clinics,
        doc: id,
        subcollections: [{collection: TableNames.ClinicPatients}],
        where: ['isDeleted', '==', false],
        storeAs: TableNames.ClinicPatients
    })

    let patientList = useSelector((state: any) => state.firestore.data.ClinicPatients);
    if  (patientList) {
        patientList = Object.keys(patientList).map((k) => patientList[k])

    }


    useFirestoreConnect({
        collection: TableNames.Clinics,
        doc: id,
        subcollections: [{ collection: TableNames.ClinicQuestions }],
       // where: ['is-active', '==', true],

        storeAs: TableNames.ClinicQuestions
    });

    useFirestoreConnect({
        collection: TableNames.Clinics,
        doc: id,
        subcollections: [{collection: TableNames.ClinicDoctors}],
        where: ['isDeleted', '==', false],
        storeAs: TableNames.ClinicDoctors

    })

    let doctorsList = useSelector((state: any) => state.firestore.data.ClinicDoctors);
    if (doctorsList) {
        doctorsList = Object.keys(doctorsList).map((k) => doctorsList[k])
    }
    //Use this for getting the clinic object
    const clinicObj = useSelector((state: any) => state.firestore.data.Clinic);

    const clinicQuestions = useSelector((state: any) => state.firestore.data.ClinicQuestions);

    const openPage = (pageName: any) => {
        setActivePage(pageName)
    }

    useEffect(()=>{
        if(location?.state?.openPage)
            openPage(location?.state?.openPage)
    },[])




    if (!clinicDetails) {
        return (
            <Loader/>
        )
    } else {

        return (
            <>
                <div className="page-header">
                    <h3 className="page-title text-capitalize">
                        {activePage != 'clinic-details' && (
                            <span className="text-dark cursor-pointer pe-3"
                                  onClick={() => {
                                      openPage('clinic-details')
                                  }
                                  }>
                                <FontAwesomeIcon icon={faChevronLeft} size="sm"/>
                            </span>
                        )}
                        {activePage == 'clinic-details' && (
                            <span className="text-dark cursor-pointer pe-3"
                                  onClick={() => {
                                      navigate('/admin/dashboard')
                                  }
                                  }>
                                <FontAwesomeIcon icon={faChevronLeft} size="sm"/>
                            </span>
                        )}
                        {activePage.replace('-', ' ')}
                    </h3>
                </div>

                <ClinicInfo
                    clinic={clinicInfo}
                />
                {activePage == 'clinic-details' && (

                <>
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="dash-widget-header">
                                <span className="dash-widget-icon text-warning border-warning">
                                    <FontAwesomeIcon icon={faBed}/>
                                </span>
                                        <div className="dash-count">
                                            <h3>{clinicInfo?.ClinicAmenities?.noOfBeds?clinicInfo?.ClinicAmenities?.noOfBeds:0}</h3>
                                        </div>
                                    </div>
                                    <div className="dash-widget-info">
                                        <h6 className="text-muted">No. of Beds Available</h6>
                                        <div className="progress progress-sm">
                                            <div className="progress-bar bg-warning w-100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="dash-widget-header">
										<span className="dash-widget-icon text-success">
                                            <FontAwesomeIcon icon={faUserInjured}/>
										</span>
                                        <div className="dash-count">
                                            <h3>{patientList && patientList.length? patientList.length:0}</h3>
                                        </div>
                                    </div>
                                    <div className="dash-widget-info">
                                        <h6 className="text-muted">No. of Patients Admitted</h6>
                                        <div className="progress progress-sm">
                                            <div className="progress-bar bg-success w-100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="dash-widget-header">
										<span className="dash-widget-icon text-primary border-primary">
                                            <FontAwesomeIcon icon={faUserMd}/>
										</span>
                                        <div className="dash-count">
                                        <h3>{ doctorsList &&  doctorsList.length? doctorsList.length:0}</h3>
                                        </div>
                                    </div>
                                    <div className="dash-widget-info">
                                        <h6 className="text-muted">No. of Doctors</h6>
                                        <div className="progress progress-sm">
                                            <div className="progress-bar bg-primary w-100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="dash-widget-info text-center">
                                        <h4>Patients List</h4>
                                        <button className="btn btn-sm btn-outline-secondary"
                                                onClick={() => {
                                                    openPage('patient-list')
                                                }
                                                }>
                                            Click here
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="dash-widget-info text-center">
                                        <h4>Form Builder</h4>
                                        <button className="btn btn-sm btn-outline-secondary"
                                                onClick={() => {
                                                    openPage('form-builder')
                                                }
                                                }>
                                            Click here
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="dash-widget-info text-center">
                                        <h4>Amenities</h4>
                                        <button className="btn btn-sm btn-outline-secondary"
                                                onClick={() => {
                                                    openPage('amenities')
                                                }
                                                }>
                                            Click here
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )}
                {activePage == 'patient-list' && (
                    <>
                    <ClinicPatientList
                        clinicId={id}/>
                    </>
                )}
                {activePage == 'form-builder' && (
                    <AdminClinicFormBuilderDetails/>
                )}
                {activePage == 'amenities' && (
                    <>
                    <ViewAmenities
                        clinic={clinicInfo}
                        clinicId={id}
                    />
                    </>
                )}
            </>
        );
    }
};
export default AdminViewClinic;
