import * as React from 'react';
import ClinicsList from './dashboard/ClinicsList';
import {
    faBed,
    faUserInjured,
    faUserMd,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useRecordsCount } from '../../hooks/count.hooks';
import {isEmpty, isLoaded, useFirestoreConnect} from 'react-redux-firebase';
import {useSelector} from "react-redux";
import {TableNames} from "../../helper/TableNames";
import PatientsList from "../clinic/dashboard/PatientsList";
import Loader from "../../components/Loader";
import {useClinic} from "../../hooks/clinic.hook";


const AdminDashboard = () => {
    const {editClinic, deleteClinic, viewClinic, statusChangeClinic, approveClinic} = useClinic()

    const { clinicCount, doctorsCount, patientsCount } = useRecordsCount();
    const [clinics, setClinics] = React.useState(0);
    const [doctors, setDoctors] = React.useState(0);
    const [patients, setPatients] = React.useState(0);


    useFirestoreConnect({
        collection: TableNames.Clinics,
        storeAs: "Clinics",
        where: ['isDeleted', '==', false],
        // orderBy:[            
        //     ['createdOn', 'desc']
        //   ] ,
    });

 

    let clinicsList = useSelector((state: any) => state.firestore.data.Clinics);
    if (clinicsList) {
        clinicsList = Object.keys(clinicsList).map((k) => clinicsList[k])
        console.log("load",clinicsList)
       
    }

    React.useEffect(()=>{

        clinicCount().then((count) => {
            setClinics(count)
        })
        doctorsCount().then((count) => {
            setDoctors(count)
        })
        patientsCount().then((count) => {
            setPatients(count)
        })
    },[clinicsList])


    return (
        <>
            <div className="page-header">
                <h3 className="page-title">Admin Dashboard</h3>
            </div>
            <div className="row">
                <div className="col-xl-3 col-sm-6 col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="dash-widget-header">
                                <span className="dash-widget-icon text-warning border-warning">
                                    <FontAwesomeIcon icon={faBed} />
                                </span>
                                <div className="dash-count">
                                    <h3>{clinics}</h3>
                                </div>
                            </div>
                            <div className="dash-widget-info">

                                <h6 className="text-muted">No. of Clinics</h6>
                                <div className="progress progress-sm">
                                    <div className="progress-bar bg-warning w-100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="dash-widget-header">
                                <span className="dash-widget-icon text-success">
                                    <FontAwesomeIcon icon={faUserInjured} />
                                </span>
                                <div className="dash-count">
                                    <h3>{patients}</h3>
                                </div>
                            </div>
                            <div className="dash-widget-info">
                                <h6 className="text-muted">No. of Patients Admitted</h6>
                                <div className="progress progress-sm">
                                    <div className="progress-bar bg-success w-100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="dash-widget-header">
                                <span className="dash-widget-icon text-primary border-primary">
                                    <FontAwesomeIcon icon={faUserMd} />
                                </span>
                                <div className="dash-count">
                                    <h3>{doctors}</h3>
                                </div>
                            </div>
                            <div className="dash-widget-info">
                                <h6 className="text-muted">No. of Doctors</h6>
                                <div className="progress progress-sm">
                                    <div className="progress-bar bg-primary w-100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="card">
                <div className="card-header d-flex justify-content-between">
                    <h5 className="card-title">Clinics List</h5>
                    <Link to="/admin/create-clinic" className="btn btn-primary">Create Clinic</Link>
                </div>
                <div className="card-body p-0">
                    {!isLoaded(clinicsList) && (
                        <Loader />
                    )}
                    {isEmpty(clinicsList) && isLoaded(clinicsList) &&(
                        <>
                            <p className="text-center my-5">No Clinics Available</p>
                        </>
                    )}
                    {clinicsList && (
                        <ClinicsList
                            clinicsList={clinicsList}
                            deleteClinic={deleteClinic}
                            editClinic={editClinic}
                            viewClinic={viewClinic}
                            statusChangeClinic={statusChangeClinic}
                            approveClinic={approveClinic}

                        />
                    )}
                </div>
            </div>
        </>
    );
};
export default AdminDashboard;
