import * as React from 'react';
import DoctorsList from "./dashboard/DoctorsList";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {isEmpty, isLoaded, useFirestoreConnect} from "react-redux-firebase";
import Loader from "../../components/Loader";
import {useDoctor} from "../../hooks/doctor.hook";
import {TableNames} from "../../helper/TableNames";

const ClinicDoctorList = (props:any) => {
    const {id} = useParams()
    const isAdmin = sessionStorage.getItem('isAdmin')


    const {deleteDoctor, editDoctor, statusChangeDoctor} = useDoctor()



    let doctorsList = useSelector((state: any) => state.firestore.data.ClinicDoctors);
    if (isLoaded(doctorsList) && doctorsList) {
        doctorsList = Object.keys(doctorsList).map((k) => doctorsList[k])
    }

    React.useEffect(()=>{

    },[doctorsList])
    return (
        <div className="card">
            {isAdmin == 'false' && (

            <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="card-title mb-0">Doctors List</h5>
                <Link to="/clinic/create-doctor" className="btn btn-primary">Create Doctor</Link>
            </div>

            )}
            <div className="card-body p-0">
                {!isLoaded(doctorsList) && (
                    <Loader />
                )}
                {isEmpty(doctorsList) && isLoaded(doctorsList) &&(
                    <>
                        <p className="text-center my-5">No Doctors Available</p>
                    </>
                )}
                {doctorsList && (
                    <DoctorsList
                        doctorsList={doctorsList}
                        deleteDoctor={deleteDoctor}
                        editDoctor={editDoctor}
                        statusChangeDoctor={statusChangeDoctor}
                    />
                )}
            </div>
        </div>
    );
};
export default ClinicDoctorList;
