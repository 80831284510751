
import ReactDOM from 'react-dom';
import ErrorBoundary from './ErrorBoundary';
import './assets/styles/app.scss'
import AppComponent from './App';

ReactDOM.render(
    <ErrorBoundary >
        <AppComponent />
    </ErrorBoundary>,
    document.getElementById("root"));
