
 export const enum TableNames {
    Clinics='Clinics',
    Questions='Questions',
    ClinicQuestions='ClinicQuestions',
    Doctors='Doctors',
    ClinicDoctors='ClinicDoctors',
    Patients='Patients',
    ClinicPatients='ClinicPatients',
    Errors='Errors',
}
