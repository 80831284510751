import * as React from 'react';
import {useEffect, useState, useMemo} from 'react'
import {useCommon} from "../../../hooks/common.hooks";
import moment from "moment";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faTrash, faEye} from "@fortawesome/free-solid-svg-icons";
import {TableHeader, Pagination, Search} from "../../../components/DataTable";
import Loader from "../../../components/Loader";
import {FirestoreClient} from "../../../library/FirestoreClient";
import {confirmAlert} from "react-confirm-alert";
import {TableNames} from "../../../helper/TableNames";
import {useSelector} from 'react-redux';
import {convertFirestoreDate, convertFirestoreTimestampToDate} from "../../../helper/firebasehelper";

const headers = [
    {name: "S.No", field: "doctorName", colsize: '1', sortable: false},
    {name: "Doctor Name", field: "doctorName", colsize: '2', sortable: false},
    {name: "Mobile Number", field: "doctorContactNumber", colsize: '2', sortable: false},
    {name: "Email ID", field: "doctorEmail", colsize: '2', sortable: false},
    {name: "Created on", field: "createdOn", colsize: '2', sortable: false},
    {name: "Status", field: "", colsize: '1', sortable: false},
    {name: "Actions", field: "", colsize: '2', sortable: false},
];

const DoctorsList = (props: any) => {
    const isAdmin = sessionStorage.getItem('isAdmin')
    let listData = props.doctorsList

    let [data, setData] = useState([...listData])

    const deleteConfirm = (item: any) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <h3>Are you sure?</h3>
                        <p>You want to delete</p>
                        <button className="btn btn-outline-danger" onClick={onClose}>No</button>
                        <button className="btn btn-primary"
                                onClick={() => {
                                    props.deleteDoctor(item);
                                    onClose()
                                }}
                        >
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });
    }


    const statusChangeConfirm = (item: any) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <h3>Are you sure?</h3>
                        <p>You want to change status</p>
                        <button className="btn btn-outline-danger" onClick={onClose}>No</button>
                        <button className="btn btn-primary"
                                onClick={() => {
                                    props.statusChangeDoctor(item);
                                    onClose()
                                }}
                        >
                            Yes
                        </button>
                    </div>
                );
            }
        });
    }


    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({field: "", order: ""});

    const ITEMS_PER_PAGE = 20;

    const searchQuery = (item: any) => {
        return (
            item.doctorName.toLowerCase().includes(search.toLowerCase()) ||
            item.doctorContactNumber.toLowerCase().includes(search.toLowerCase()) ||
            item.doctorEmail.toLowerCase().includes(search.toLowerCase())
        )
    }

    const compareQuery = (a: any, b: any) => {
        const reversed = sorting.order === "asc" ? 1 : -1;
        return reversed * a[sorting.field].localeCompare(b[sorting.field]);
    }

    // listData = useMemo(() => {
    //     if (search) {
    //         listData = listData.filter(searchQuery);
    //     }
    //     setTotalItems(listData.length);

    //     if (sorting.field) {
    //         listData = listData.sort(compareQuery)
    //     }

    //     //Current Page slice
    //     return listData.slice(
    //         (currentPage - 1) * ITEMS_PER_PAGE,
    //         (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    //     );
    // }, [listData, currentPage, search, sorting]);

    useEffect(() => {

        let tempData = [...listData]
        if (search) {
            tempData = tempData.filter(searchQuery);
            setTotalItems(0);
        } else {
            setTotalItems(tempData.length);
            tempData = tempData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        }

        if (sorting.field) {
            tempData = tempData.sort(compareQuery)
        }

        setData(tempData)
    }, [search, currentPage])

    useEffect(()=>{
        let tempData = [...listData]

        setData(tempData)
    },[listData])
    return (
        <>
            <div className="row justify-content-end mx-0 my-3">
                <div className="col-md-12 d-flex justify-content-end">
                    <Search
                        onSearch={(value: any) => {
                            setSearch(value);
                        }}
                    />
                </div>
            </div>
            <div className={`grid-wrapper doctor-list-wrapper ${isAdmin == 'true' ? 'isadmin' : '' }`}>
                <TableHeader
                    headers={headers}
                    onSorting={(field: any, order: any) =>
                        setSorting({field, order})
                    }
                />
                {data.map((item: any, index: any) => (
                  item &&  <div className="grid-block" key={index}>
                        <div className="row">
                            <div className="col-1"> {index + 1} </div>
                            <div className="col-2">{item['doctorName']} </div>
                            <div className="col-2">{item['doctorContactNumber']}</div>
                            <div className="col-2">{item['doctorEmail']}</div>
                            <div
                                className="col-2">{convertFirestoreTimestampToDate(item['createdOn'])}</div>
                            {isAdmin == 'false' && (
                                <>
                                    <div className="col-1">
                                        {item['isActive'] ? (
                                            <span className="badge badge-success cursor-pointer"
                                                  onClick={() => {
                                                      statusChangeConfirm(item)
                                                  }}
                                            >
                                            Active
                                        </span>
                                        ) : (
                                            <span className="badge badge-danger cursor-pointer"
                                                  onClick={() => {
                                                      statusChangeConfirm(item)
                                                  }}
                                            >
                                            Inactive
                                        </span>
                                        )}

                                    </div>
                                    <div className="col-2">
                                        <button type="button" className="btn btn-sm btn-outline-secondary"
                                                onClick={() => {
                                                    props.editDoctor(item)
                                                }
                                                }>
                                            <FontAwesomeIcon icon={faPencilAlt}/>
                                        </button>
                                        <button type="button" className="btn btn-sm btn-outline-secondary"
                                                onClick={() => {
                                                    deleteConfirm(item)
                                                }
                                                }>
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {!search && <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page: any) => setCurrentPage(page)}
            />}
        </>
    );

};
export default DoctorsList;
