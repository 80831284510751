import { ICustom } from "./ClinicType";

export interface IDoctor{
    id:string
    doctorName:string,
    doctorEmail:string,
    doctorContactNumber:string,
    doctorCustoms:ICustom[],
    createdOn?:firebase.firestore.Timestamp,
    isActive?:boolean,
    isDeleted?:boolean,

}

export class DoctorType implements IDoctor
{
    constructor(
        public  id: string,
        public doctorName: string,
        public doctorEmail: string,
        public doctorContactNumber:string,
        public doctorCustoms:ICustom[],
        public createdOn?:firebase.firestore.Timestamp ) {
    }
    isActive?: boolean | undefined;
    isDeleted?: boolean | undefined;


}
