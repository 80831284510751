import React from "react";
import { useSelector } from 'react-redux';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { ROLE } from '../helper/Roles'

const PrivateRoute = ({ children,role }: { children: JSX.Element, role:ROLE; }) => {
  let location = useLocation();
  const userType= sessionStorage.getItem("isAdmin")==='true'?'Admin':'Clinic';


  const auth = useSelector((state:any) => state.firebase.auth);

  console.log(auth)
  if (!auth.isLoaded) {
    return <p>Loading..</p>;
  }

  const userHasRequiredRole = auth && role.includes(userType) ? true : false;

  console.log(userHasRequiredRole)
  if (!auth.email && !userHasRequiredRole) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  if (auth.email  && !userHasRequiredRole) {
    return window.location.href="/404.html"
  }
  return children;
};

export default PrivateRoute;