import * as React from 'react';
import Amenities from "./Amenities";
import ClinicDoctorList from "../clinic/DoctorList";
import {useState} from "react";

const ViewAmenities = (props: any) => {
    const [activePage, setActivePage] = useState('doctors')
    const openPage = (pageName: any) => {
        setActivePage(pageName)
    }
    return (
        <>

            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <button className={activePage === 'doctors' ? 'nav-link active' : 'nav-link text-dark'}
                            onClick={() => {
                                openPage('doctors')
                            }}>
                        Doctors
                    </button>
                </li>
                <li className="nav-item">
                    <button className={activePage === 'amenities' ? 'nav-link active' : 'nav-link text-dark'}
                            onClick={() => {
                                openPage('amenities')
                            }}>
                        Amenities
                    </button>
                </li>
            </ul>
            <div className="tab-content">
                {activePage == 'doctors' && (
                    <ClinicDoctorList
                        clinicId={props?.clinicId}
                    />
                )}
                {activePage == 'amenities' && (
                    <Amenities
                        clinic={props?.clinic}
                    />

                )}

            </div>
        </>
    )
};
export default ViewAmenities;

