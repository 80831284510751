import {FirestoreClient} from '../library/FirestoreClient'
import firebase from 'firebase';
import {TableNames} from "../helper/TableNames";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const db = firebase.firestore();
let clinicId = sessionStorage.getItem('clinicid')

export const useDoctor = () => {
    const navigate = useNavigate()
    const deleteDoctor = (item: any) => {
        FirestoreClient.updateSubCollection(TableNames.Clinics, clinicId, TableNames.ClinicDoctors, {isDeleted: true}, item['id']).then(() => {

          
            toast.success('Deleted successfully')
            console.log('Deleted successfully')
            // setTimeout(() => {
            //     window.location.href = "/clinic/amenities"

            // }, 1000)

        }).catch(err => err)
    }
    const editDoctor = (item: any) => {
        navigate("/clinic/create-doctor", {state: item})
    }
    const statusChangeDoctor = (item: any) => {
       
        let activeStatus = !item['isActive']
        FirestoreClient.updateSubCollection(TableNames.Clinics, clinicId, TableNames.ClinicDoctors, {isActive: activeStatus}, item['id']).then(() => {
            toast.success('Status Updated successfully')
            console.log("Status Updated successfully")
            // setTimeout(() => {
            //     window.location.href = "/clinic/amenities"

            // }, 1000)
        }).catch(err => err)
    }

    return {
        deleteDoctor,
        editDoctor,
        statusChangeDoctor
    }
}
