import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const Loader = () => {
    return (
        <div className="text-center p-5">
            <FontAwesomeIcon className="fa-spin fa-4x" icon={faSpinner}/>
        </div>
    );
};

export default Loader;
