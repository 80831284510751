import * as React from 'react';
import { useState, useMemo, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faMobileAlt, faDesktop, faPencilAlt, faTrash, faRedo } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate, useParams } from "react-router-dom";
import { TableHeader, Pagination, Search } from "../../../components/DataTable";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import { convertFirestoreDate, convertFirestoreTimestampToDate } from "../../../helper/firebasehelper";
import DateRangePickerCompoment from "../../../components/DateRange";

const headers = [
    { name: "S.No", field: "firstName", colsize: '1', sortable: false },
    { name: "Patient Name", field: "firstName", colsize: '2', sortable: false },
    { name: "Phone", field: "phone", colsize: '2', sortable: false },
    { name: "Email", field: "email", colsize: '2', sortable: false },
    { name: "Created On", field: "createdOn", colsize: '1', sortable: false },
    { name: "Checked From", field: "checkedFrom", colsize: '1', sortable: false },
    { name: "Status", field: "isActive", colsize: '1', sortable: false },
    { name: "Actions", field: "", colsize: '2', sortable: false },
];

const PatientsList = (props: any) => {
    const { id } = useParams()
    const isAdmin = sessionStorage.getItem('isAdmin')

    let listData = props.patientList

    let [data, setData] = useState([...listData])

    const startInitialDate = "01/01/" + new Date().getFullYear()
    const endInitialDate = "12/31/" + new Date().getFullYear()

    const  [startDate,setStartDate] =useState(startInitialDate)
    const  [endDate,setEndDate] =useState(endInitialDate)

    const navigate = useNavigate()
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 20;

    const viewPatient = (item: any) => {
        isAdmin == 'true' ? navigate("/admin/patient-details/" + id + "/" + item.id) : navigate("/clinic/patient-details/" + item.id)
    }

    const deleteConfirm = (item: any) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h3>Are you sure?</h3>
                        <p>You want to delete</p>
                        <button className="btn btn-outline-danger" onClick={onClose}>No</button>
                        <button className="btn btn-primary"
                            onClick={() => {
                                props.deletePatient(item);
                                onClose()
                            }}
                        >
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });
    }
    const statusChangeConfirm = (item: any) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h3>Are you sure?</h3>
                        <p>You want to change status</p>
                        <button className="btn btn-outline-danger" onClick={onClose}>No</button>
                        <button className="btn btn-primary"
                            onClick={() => {
                                props.statusChangePatient(item);
                                onClose()
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            }
        });
    }

    const searchQuery = (item: any) => {
        return (
            item.firstName.toLowerCase().includes(search.toLowerCase()) ||
            item.lastName.toLowerCase().includes(search.toLowerCase()) ||
            item.lastName.toLowerCase().includes(search.toLowerCase()) ||
            item.phone.toLowerCase().includes(search.toLowerCase()) ||
            item.email.toLowerCase().includes(search.toLowerCase()) /*||
            item.Address1.toLowerCase().includes(search.toLowerCase())*/
        )
    }

    const compareQuery = (a: any, b: any) => {
        const reversed = sorting.order === "asc" ? 1 : -1;
        return reversed * a[sorting.field].localeCompare(b[sorting.field]);
    }

    // listData = useMemo(() => {
    //     if (search) {
    //         listData = listData.filter(searchQuery);
    //     }
    //     setTotalItems(listData.length);

    //     if (sorting.field) {
    //         listData = listData.sort(compareQuery)
    //     }

    //     //Current Page slice
    //     return listData.slice(
    //         (currentPage - 1) * ITEMS_PER_PAGE,
    //         (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    //     );
    // }, [listData, currentPage, search, sorting]);
    useEffect(() => {

        let tempData = [...listData]
        if (search) {
            tempData = tempData.filter(searchQuery);
            setTotalItems(0);
        } else {
            setTotalItems(tempData.length);
            tempData = tempData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        }

        if (sorting.field) {
            tempData = tempData.sort(compareQuery)
        }
        tempData = tempData.filter(a => {
            var date = new Date(convertFirestoreTimestampToDate(a.createdOn));
            return (date >= new Date(startDate) && date <= new Date(endDate));
        });
        setData(tempData)
 
    }, [search, currentPage,startDate,endDate])

    useEffect(() => {
        let tempData = [...listData]

        setData(tempData)
    }, [listData])


    const filterPatientsOnDateRange = (event: any, picket: any) => {
        const startDate = new Date(moment(picket.startDate.toDate()).format("DD/MM/YYYY"))
        const endDate = new Date(moment(picket.endDate.toDate()).format("DD/MM/YYYY"))

        let tempData = [...listData]

        var resultData = tempData.filter(a => {
            var date = new Date(convertFirestoreTimestampToDate(a.createdOn));
            return (date >= startDate && date <= endDate);
        });


        setData(resultData)


        setStartDate(moment(picket.startDate.toDate()).format("DD/MM/YYYY"))
        setEndDate(moment(picket.endDate.toDate()).format("DD/MM/YYYY"))


    }
    return (
        <>
            <div className="row justify-content-end mx-0 my-3">
                <div className="col-md-6 d-flex justify-content-end">
                    <Search
                        onSearch={(value: any) => {
                            setSearch(value);
                        }}
                    />
                </div>
                <div className="col-md-3 d-flex">
                    <DateRangePickerCompoment  filterPatientsOnDateRange={filterPatientsOnDateRange} startDate={startDate} endDate={endDate} />
                    <button type="button" className="btn btn-sm btn-outline-secondary" title='Reset'
                        onClick={() => {
                            let tempData = [...listData]

                            setData(tempData)

                            setStartDate(startInitialDate)
                            setEndDate(endInitialDate)

                        }
                        }>
                        <FontAwesomeIcon icon={faRedo} />
                    </button>
                </div>
            </div>
            <div className={`grid-wrapper patients-list-wrapper ${isAdmin == 'true' ? 'isadmin' : ''}`}>
                <TableHeader
                    headers={headers}
                    onSorting={(field: any, order: any) =>
                        setSorting({ field, order })
                    }
                />
                {data && data.map((item: any, index: any) => (
                    item && <div className="grid-block">
                        <div className="row" key={index}>
                            <div className="col-1"> {index + 1} </div>
                            <div
                                className="col-2"> {item['firstName'] + ' ' + item['middleName'] + ' ' + item['lastName']} </div>
                            <div className="col-2">{item['phone']}</div>
                            <div className="col-2">{item['email']}</div>
                            <div className="col-1">{convertFirestoreTimestampToDate(item['createdOn'])}</div>
                            <div className="col-1">
                                {item['p_check_from'] == 'Mobile' ? (
                                    <FontAwesomeIcon icon={faMobileAlt} size="lg" />
                                ) : (
                                    <FontAwesomeIcon icon={faDesktop} size="lg" />
                                )}

                            </div>
                            <div className="col-1">
                                {isAdmin == 'false' && (
                                    <>
                                        {item['isActive'] ? (
                                            <span className="badge badge-success cursor-pointer"
                                                onClick={() => {
                                                    statusChangeConfirm(item)
                                                }}
                                            >
                                                Active
                                            </span>
                                        ) : (
                                            <span className="badge badge-danger cursor-pointer"
                                                onClick={() => {
                                                    statusChangeConfirm(item)
                                                }}
                                            >
                                                Inactive
                                            </span>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="col-2">
                                {isAdmin == 'false' && (
                                    <>
                                        <button type="button" className="btn btn-sm btn-outline-secondary"
                                            onClick={() => {
                                                props.editPatient(item)
                                            }
                                            }>
                                            <FontAwesomeIcon icon={faPencilAlt} />
                                        </button>
                                        <button type="button" className="btn btn-sm btn-outline-secondary"
                                            onClick={() => {
                                                deleteConfirm(item)

                                            }
                                            }>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </>
                                )}
                                <button className="btn btn-sm btn-outline-secondary"
                                    onClick={() => {
                                        viewPatient(item)
                                    }
                                    }>
                                    <FontAwesomeIcon icon={faEye} />
                                </button>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
            {!search && <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page: any) => setCurrentPage(page)}
            />}
        </>
    );
};
export default PatientsList;
