import {FirestoreClient} from '../library/FirestoreClient'
import firebase from 'firebase';
import {TableNames} from "../helper/TableNames";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const db = firebase.firestore();
let clinicId = sessionStorage.getItem('clinicid')

export const usePatient = () => {
    const navigate = useNavigate()
    const deletePatient = (item: any) => {
        FirestoreClient.updateSubCollection(TableNames.Clinics, clinicId, TableNames.ClinicPatients, {isDeleted: true}, item['id']).then(() => {
            console.log("Deleted successfully")
            toast.success('Deleted successfully')
            // setTimeout(() => {
            //     window.location.href = "/clinic/dashboard"

            // }, 1000)
        }).catch(err => err)
    }
    const editPatient = (item: any) => {
        navigate("/clinic/create-patient", {state: item})
    }
    const statusChangePatient = (item: any) => {
        let activeStatus = !item['isActive']
        FirestoreClient.updateSubCollection(TableNames.Clinics, clinicId, TableNames.ClinicPatients, {isActive: activeStatus}, item['id']).then(() => {
            console.log("Status Updated successfully")

            toast.success('Status Updated successfully')
            // setTimeout(() => {
            //     window.location.href = "/clinic/dashboard"

            // }, 1000)
        }).catch(err => err)
    }

    return {
        deletePatient,
        editPatient,
        statusChangePatient
    }
}
