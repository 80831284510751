import * as React from 'react';
import { QuestionComponent } from './formbuilder/QuestionComponent';
import { useFormBuilder } from '../../hooks/formbuilder.hook.';

import { trackPromise } from 'react-promise-tracker';
import { FormBuilderType, IFormBuilder } from '../../models/FormBuilderType';

import { currentDate } from '../../helper/firebasehelper';
import PreviewForm from './formbuilder/Preview'
import { confirmAlert } from 'react-confirm-alert';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { TableNames } from '../../helper/TableNames';
import log from 'loglevel';
import { useErrorHandling } from '../../ErrorBoundary';



const ClinicFormBuilder = () => {

    //For now we are reading the clinicid from the sessionstorage. Once we have  clinic context created we will read  from it
    const ClinicId: any = sessionStorage.getItem('clinicid')

    const initialState: IFormBuilder[] = [];
    const initialSelectedQuestion: IFormBuilder[] = [];

    const tempQuestions: IFormBuilder[] = []
    let isQuestionsLoaded = false;

    const [editQuestion, setEditQuestion] = React.useState('');

    let [questions, setQuestions] = React.useState(initialState);

    const [questionsReadyToPublish, setQuestionsReadyToPublish] = React.useState(initialState);


    const [questionsSelected, setQuestionsSelected] = React.useState(initialSelectedQuestion);


    const [isSelectAllChecked, setIsSelectAllChecked] = React.useState(false);


    const { updateClinicQuestion, convertStringToFormbuilderType } = useFormBuilder();


    const [isNewClicked, setIsNewClicked] = React.useState(false)

    const [isOpen, setIsOpen] = React.useState(false);
    const { triggerError } = useErrorHandling();


    try {
        useFirestoreConnect({
            collection: TableNames.Clinics,
            doc: ClinicId,
            subcollections: [{ collection: TableNames.ClinicQuestions }],
            // where: ['is-active', '==', true],

            storeAs: TableNames.ClinicQuestions
        });

    } catch (error) {

      //  triggerError(error)

    }




    const clinicQuestions = useSelector((state: any) => state.firestore.data.ClinicQuestions);

    React.useEffect(() => {

        tempQuestions.sort((a, b) => a.sequence - b.sequence)
        setQuestions(tempQuestions)
        isQuestionsLoaded = false;

    }, [clinicQuestions]);


    if (isLoaded(clinicQuestions)) {
        try {

            if (clinicQuestions === null) return <h6>No Questions Found</h6>

            let filteredClinicQuestions = Object.values(clinicQuestions).filter((item: any) => item['isActive'] === true);

            filteredClinicQuestions.forEach((element: any) => {
                let builder: IFormBuilder = convertStringToFormbuilderType(element)
                tempQuestions.push(builder)

            });
            isQuestionsLoaded = true;

        } catch (error) {
          //triggerError(error)
        }


    }


    const togglePopup = () => {



        if (questionsSelected.length === 0) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h3>Select atleast one question before you Preview.</h3>
                            <button className="btn btn-success" onClick={() => {
                                onClose()


                            }}>Close</button>

                        </div>
                    );
                }
            })
        }
        else {
            questionsSelected.sort((a, b) => a.sequence - b.sequence)
            setIsOpen(!isOpen);
        }
    }



    //Shows the alert message 
    const publishedQuestionsMessage = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h3>Processed successfully.</h3>
                        <button className="btn btn-success" onClick={() => {
                            onClose()


                        }}>Close</button>

                    </div>
                );
            }
        })

    }

    const flipPublishStaus = (status: boolean) => {

        try {
            if (questionsSelected.length === 0) {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h3>Select atleast one question before you proceed.</h3>
                                <button className="btn btn-success" onClick={() => {
                                    onClose()


                                }}>Close</button>

                            </div>
                        );
                    }
                })

                return;
            }
            //  console.log(questionsSelected)
            //Loop through all the selected questions for publish
            questionsSelected.forEach((item) => {
                item.isPublished = status

                trackPromise(
                    updateClinicQuestion(ClinicId, item, item.id).then((res) => {



                    })
                )

            })
            publishedQuestionsMessage();
            setQuestionsSelected([]);
            refreshQuestions()


            // changeStatusForSelectAll(false)
            setIsSelectAllChecked(false)
        } catch (error) {
           // triggerError(error)
        }


    }


    //Handle SelectAll checkekbox onchange event

    const handleSelectAllOnchange = (event: any) => {
        if (event.target.checked) {

            setIsSelectAllChecked(event.target.checked)
        }
        else
            setIsSelectAllChecked(event.target.checked)


    }

    //Gets fired when the user clicks the Delete Icon of child component
    const deleteQuestionFromList = (id: string, sequence: number) => {

        try {
            //Get all the questions which are next in the sequence of the current item
            const filteredQuestions = questions.filter((item) => {
                return item.sequence > sequence
            })

            //Update the sequence of each the question of filteredQuestions collection
            filteredQuestions.forEach((updateQuestion) => {
                updateQuestion.sequence = updateQuestion.sequence - 1;
                updateClinicQuestion(ClinicId, updateQuestion, updateQuestion.id)
            })

            //Show only those questions apart the deleted one
            const remainingQuestions = questions.filter((item) => item.id !== id)

            setQuestions(remainingQuestions);
            setQuestionsSelected([])
            isQuestionsLoaded = true
        } catch (error) {
          //  triggerError(error)

        }

    }

    //Refresh  clinic questions after adding. This is called from child component
    const refreshQuestions = () => {


        tempQuestions.sort((a, b) => a.sequence - b.sequence)
        setQuestions(tempQuestions)


    }

    const changeStatusForSelectAll = (value: boolean) => {
        const selectedQuestions: IFormBuilder[] = []
        const copyQuestions = [...tempQuestions]

        try {
            setQuestionsSelected([])
            setQuestions([])
            if (value) {

                copyQuestions.forEach((item) => {
                    item.isChecked = true
                    selectedQuestions.push(item)
                }
                )
                setIsSelectAllChecked(true)
            }
            else {
                copyQuestions.forEach((item) => {
                    item.isChecked = false
                   
                }
                )
                setIsSelectAllChecked(false)

            }

            setQuestionsSelected(selectedQuestions)
            copyQuestions.sort((a,b)=>a.sequence-b.sequence)
            //   console.log(tempQuestions)
            setQuestions(copyQuestions)


        } catch (error) {
           // triggerError(error)

        }


    }
    //Refresh  clinic questions after adding. This is called from child component
    const updateQuestions = (id: string, updatedQuestion: any) => {

        tempQuestions.sort((a, b) => a.sequence - b.sequence)
        setQuestions(tempQuestions)


    }


    const updatePositionsHandler = (id: any, position: number, action: string) => {

        try {
            //Modify the  postion of entity ,which is in the currently in same  index of the modified question
            const prevEntityIndex = tempQuestions.findIndex((entity) => entity.sequence === position);
            const currentEntityIndex = tempQuestions.findIndex((entity) => entity.id === id);


            const refreshedQuestions = [...tempQuestions];


            if (action === "up") {
                refreshedQuestions[prevEntityIndex].sequence = position + 1;
            }
            else {
                refreshedQuestions[prevEntityIndex].sequence = position - 1;

            }


            //set new postion of the selected question
            refreshedQuestions[currentEntityIndex].sequence = position;
            refreshedQuestions.sort((a, b) => a.sequence - b.sequence)

            refreshedQuestions.forEach((item) => {

                updateClinicQuestion(ClinicId, item, item.id).catch((error: any) => console.log(error));

            })
            setQuestions(refreshedQuestions);

        } catch (error) {
          //  triggerError(error)
        }

    }
    //Add a dummy row to the collection so that the child form will show with empty values
    //Gets fired when user clicks Add button
    const addNewRow = () => {

        if (editQuestion !== '') return;
        let newRow = new FormBuilderType('', '', 'textbox', [], questions.length + 1, false, false, currentDate, true);
        setIsNewClicked(true);

        const temp = questions = [...questions, newRow]
        setQuestions(temp);



    }
    //Removes the dummy record from the quections collection.Fired when user clicks the Cancel button
    const cancelNewRow = () => {

        setIsNewClicked(false);
        let rows = [...questions.filter(x => x.id !== '')];
        setQuestions(rows);



    }

    const Popup = (props: any) => {

        return (
            <div className="popup-box">
                <div className="box">

                    {props.content}
                </div>
            </div>
        );
    };


    return (

        <>
    

            <div className="page-header">
                <div className="card-header d-flex justify-content-between align-items-center">

                    <h3 className="page-title">Form Builder</h3>

                    <div className="d-flex d-inline-flex justify-content-end">
                        <button className='btn btn-primary' onClick={togglePopup}>Preview</button>
                        <button className='btn btn-primary' onClick={() => {
                            flipPublishStaus(true)
                            //  setIsSelectAllChecked(false);
                            //  setQuestionsSelected([])
                        }}>Publish</button>
                        <button className='btn btn-primary' onClick={() => {
                            flipPublishStaus(false)
                            // setIsSelectAllChecked(false);
                            // setQuestionsSelected([])
                        }}>Unpublish</button>
                    </div>
                </div>
            </div>
            <div className='grid-wrapper'>
                <div className="grid-header">
                    <div className="row">
                        <div className="col-1 text-capitalize">
                            <label className=' d-inline-flex space-wrap align-items-center'>

                                <input type='checkbox' className='d-flex justify-content-center mr-5' name='selectAll' checked={isSelectAllChecked} onChange={(event) => {
                                    if (isNewClicked || editQuestion !== '') return;

                                    setIsSelectAllChecked(event.target.checked)
                                    changeStatusForSelectAll(event.target.checked)
                                }
                                }></input>Select All
                            </label>
                        </div>
                        <div className="col-1 text-capitalize">

                        </div>
                        <div className="col-3 text-capitalize">
                            Question Title
                        </div>
                        <div className="col-2 text-capitalize">
                            Question Type
                        </div>
                        <div className="col-2 text-capitalize">
                            Answers
                        </div>

                        <div className="col-1">
                            Created On
                        </div>
                        <div className="col-1 text-capitalize">
                            Actions
                        </div>
                        <div className="col-1 text-capitalize">
                            Status
                        </div>
                    </div>
                </div>


                {

                    questions && questions.map((item) => {

                        return <div className={'grid-block ' + (isNewClicked ? item.id !== "" ? 'opacity-25' : '' : '')}   >
                            <QuestionComponent key={item.id}
                                clinicId={ClinicId}
                                editQuestion={editQuestion === '' ? false : true}
                                isNewClicked={isNewClicked}
                                isSelectAllChecked={isSelectAllChecked}
                                questionsReadyToPublish={questionsReadyToPublish}
                                questionsSelected={questionsSelected}
                                isPending={item.isPublished || isSelectAllChecked}
                                callerMethods={[deleteQuestionFromList, refreshQuestions, updateQuestions, updatePositionsHandler]}
                                setActions={[setIsNewClicked, setEditQuestion, setIsSelectAllChecked, setQuestionsSelected]}
                                builder={item}
                                totalRows={questions.length} ></QuestionComponent>

                        </div>
                    }
                    )

                }
            </div>
            <div>
                {
                    !isNewClicked ?
                        <button className='btn btn-primary' onClick={addNewRow}>Add New</button> :
                        <button className='btn btn-primary' onClick={cancelNewRow}>Cancel </button>

                }
            </div>
            {


                isOpen && <Popup
                    content={<>
                        <PreviewForm questions={questionsSelected} handleClose={togglePopup}></PreviewForm>
                    </>}

                />}


        </>
    );
};
export default ClinicFormBuilder;
