import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClinicMedical, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef } from 'react';

const DateRangePickerCompoment = (props: any) => {

    const ref=useRef<DateRangePicker>(null)

    useEffect(()=>{
        console.log(props.startDate)

        ref.current?.setStartDate ( props.startDate)
        ref.current?.setEndDate ( props.endDate)

    },[ props.startDate, props.endDate])


    return ( 
        <>
        

            <DateRangePicker
                initialSettings={{
                    startDate: props.startDate,
                    endDate: props.endDate
                }}
                ref={ref}
               
                onApply={props.filterPatientsOnDateRange}
            >
               
                    <input name="selectrangedate" type="text" className="form-control"  />
               

            </DateRangePicker>
        </>
    );
};

export default DateRangePickerCompoment;
