import * as React from 'react';
import {Link, useLocation} from "react-router-dom";
import {
    faClinicMedical, faClipboardList,
    faMapMarkerAlt,
    faClipboard, faAtom
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Navbar = () => {
    const location = useLocation()
    let pathname = location.pathname
    const exclusionArray = [
        "/",
        "/login",
        "/register",
        "/forgotPassword",
        "/404",
        "/500",
    ];
    const isAdmin = sessionStorage.getItem('isAdmin')
    if (exclusionArray.indexOf(pathname) >= 0) {
        document.body.classList.add('no-sidebar');
        return "";
    } else {
        document.body.classList.remove('no-sidebar');
    }

    return (
        <>
            <div className="sidebar" id="sidebar">
                <div className="sidebar-inner slimscroll">
                    <div id="sidebar-menu" className="sidebar-menu">
                        {isAdmin == 'true' && (
                            <ul>
                                <li className="menu-title">
                                    <span>Admin</span>
                                </li>
                                <li className={pathname === '/admin/dashboard' ? 'active' : ''}>
                                    <Link to="/admin/dashboard"><i className="fe fe-home"/>
                                        <span>Dashboard</span></Link>
                                </li>
                                <li className={pathname === '/admin/create-clinic' ? 'active' : ''}>

                                    <Link to="/admin/create-clinic">
                                        <FontAwesomeIcon icon={faClinicMedical}/>
                                        <span>Create Clinic</span></Link>
                                </li>
                                {/*<li className={pathname === '/admin/view-clinic' ? 'active' : ''}>
                                    <Link to="/admin/view-clinic">
                                        <FontAwesomeIcon icon={faClinicMedical}/>
                                        <span>View Clinic</span></Link>
                                </li>*/}
                                {/*<li className={pathname === '/admin/clinic-patient-list' ? 'active' : ''}>
                                <Link to="/admin/clinic-patient-list"><i className="fe fe-home" /> <span>Clinic Patients List</span></Link>
                            </li>
                            <li className={pathname === '/admin/clinic-form-builder-details' ? 'active' : ''}>
                                <Link to="/admin/clinic-form-builder-details"><i className="fe fe-home" /> <span>Clinic Form Builder</span></Link>
                            </li>
                            <li className={pathname === '/admin/amenities' ? 'active' : ''}>
                                <Link to="/admin/amenities"><i className="fe fe-home" /> <span>Amenities</span></Link>
                            </li>
                            <li className={pathname === '/admin/clinic-doctors' ? 'active' : ''}>
                                <Link to="/admin/clinic-doctors"><i className="fe fe-home" /> <span>Clinic Doctors</span></Link>
                            </li>*/}
                            </ul>
                        )}
                        {isAdmin == 'false' && (
                            <ul>
                                <li className="menu-title">
                                    <span>Clinic</span>
                                </li>
                                <li className={pathname === '/clinic/dashboard' ? 'active' : ''}>
                                    <Link to="/clinic/dashboard">
                                        <FontAwesomeIcon icon={faClinicMedical}/>
                                        <span>Dashboard</span></Link>
                                </li>
                                <li className={pathname === '/clinic/checkin' ? 'active' : ''}>
                                    <Link to="/clinic/checkin">
                                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                        <span>Checkin</span></Link>
                                </li>
                                {/*<li className={pathname === '/clinic/create-patient' ? 'active' : ''}>
                                    <Link to="/clinic/create-patient">
                                        <FontAwesomeIcon icon={faUserInjured}/>
                                        <span>Create Patient</span></Link>
                                </li>
                                <li className={pathname === '/clinic/patient-list' ? 'active' : ''}>
                                    <Link to="/clinic/patient-list">
                                        <FontAwesomeIcon icon={faClipboardList}/>
                                        <span>Patients</span></Link>
                                </li>
                                <li className={pathname === '/clinic/doctor-list' ? 'active' : ''}>
                                    <Link to="/clinic/doctor-list">
                                        <FontAwesomeIcon icon={faClipboardList}/>
                                        <span>Doctors</span></Link>
                                </li>
                                <li className={pathname === '/clinic/patient-details' ? 'active' : ''}>
                                    <Link to="/clinic/patient-details">
                                        <FontAwesomeIcon icon={faHospitalUser}/>
                                        <span>Patient Details</span></Link>
                                </li>*/}
                                <li className={pathname === '/clinic/form-builder' ? 'active' : ''}>
                                    <Link to="/clinic/form-builder">
                                        <FontAwesomeIcon icon={faClipboard}/>
                                        <span>Form Builder</span></Link>
                                </li>
                                {/*<li className={pathname === '/clinic/create-doctor' ? 'active' : ''}>
                                    <Link to="/clinic/create-doctor">
                                        <FontAwesomeIcon icon={faUserMd}/>
                                        <span>Create Doctor</span></Link>
                                </li>*/}
                                <li className={pathname === '/clinic/amenities' ? 'active' : ''}>
                                    <Link to="/clinic/amenities">
                                        <FontAwesomeIcon icon={faAtom}/>
                                        <span>Amenities</span></Link>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default Navbar;
