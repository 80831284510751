import * as React from 'react';
import {
    faPlus,
    faMinus,
    faBed,
    faUserInjured,
    faUserMd,
    faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate, Link} from "react-router-dom";
import {useFirestoreConnect, isLoaded, isEmpty} from 'react-redux-firebase';
import {useSelector} from 'react-redux';
import {TableNames} from '../../helper/TableNames';
import ClinicPatientList from "./PatientList";
import {useEffect, useState} from "react";
import {useClinic} from "../../hooks/clinic.hook";


const ClinicDashboard = () => {
    const {updateNoOfBeds} = useClinic()
    const [noOfBeds, setNoOfBeds] = useState(0)
    const [disabled, setDisabled] = useState(false)

    let clinicId:any = sessionStorage.getItem('clinicid')
    let clinicInfo: any = []

    const emailId = sessionStorage['email']
    useFirestoreConnect({
        collection: `Clinics`,
        storeAs: "Clinic",
        where: ['clinicEmail', '==', emailId],
    });

    //Use this for getting the clinic object
    const clinicDetails = useSelector((state: any) => state.firestore.data.Clinic);
    if (isLoaded(clinicDetails)) {
        clinicInfo = Object.values(clinicDetails);
        clinicInfo = clinicInfo[0]
    }

    // clincs\clinicdoctors state
    useFirestoreConnect({
        collection: TableNames.Clinics,
        doc: clinicId || '',
        subcollections: [{collection: TableNames.ClinicDoctors}],
        where: ['isDeleted', '==', false],
        storeAs: TableNames.ClinicDoctors
    })

    let doctorsList = useSelector((state: any) => state.firestore.data.ClinicDoctors);
    if (doctorsList) {
        doctorsList = Object.keys(doctorsList).map((k) => doctorsList[k])
    }
    //Getting clincs\clinicpatients state
    useFirestoreConnect({
        collection: TableNames.Clinics,
        doc: clinicId || '',
        subcollections: [{collection: TableNames.ClinicPatients}],
        where: ['isDeleted', '==', false],
        storeAs: TableNames.ClinicPatients
    })

    let patientList = useSelector((state: any) => state.firestore.data.ClinicPatients);
    if (patientList) {
        patientList = Object.keys(patientList).map((k) => patientList[k])
    }


    let navigate = useNavigate();
    const goToCheckin = () => {
        navigate("/clinic/checkin")
    }
    useEffect(() => {
        let count:number = 0
        if(clinicInfo?.noOfBedsAvailable) {
            count = parseInt(clinicInfo?.noOfBedsAvailable)
        } else if (clinicInfo?.ClinicAmenities?.noOfBeds){
            count = parseInt(clinicInfo?.ClinicAmenities?.noOfBeds)
        }
        setNoOfBeds(count)
    }, [clinicInfo,patientList])


    const handleIncrement = ()=> {
        setDisabled(true)
        setNoOfBeds(noOfBeds + 1)
        updateBedCount()
    }
    const handleDecrement = ()=> {
        setDisabled(true)
        if(noOfBeds > 0)
            setNoOfBeds(noOfBeds - 1)
        updateBedCount()
    }


    const updateBedCount = () => {
        updateNoOfBeds(noOfBeds, clinicId).then(()=>{
            setDisabled(false)
        })
    }

    return (
        <>
            <div className="page-header">
                <h3 className="page-title">Clinic Dashboard</h3>
            </div>
            <div className="row">
                <div className="col-xl-3 col-sm-6 col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="dash-widget-header">
                                <span className="dash-widget-icon text-warning border-warning">
                                    <FontAwesomeIcon icon={faBed}/>
                                </span>
                                <div className="dash-count col">
                                    <div className="row align-items-center m-0">
                                        <div className="col-3 p-0 text-end">
                                            <button className="btn btn-outline-secondary btn-sm"
                                                    disabled={disabled}
                                                    onClick={() => {
                                                        handleIncrement()
                                                            }}
                                            >
                                                <FontAwesomeIcon icon={faPlus}/>
                                            </button>
                                        </div>
                                        <div className="col-6 p-0">
                                            <div className="form-control text-center p-2">
                                                {noOfBeds > 0 ? noOfBeds : 0}
                                            </div>
                                        </div>
                                        <div className="col-3 p-0">
                                            <button className="btn btn-outline-secondary btn-sm"
                                                    disabled={disabled}
                                                    onClick={() => {
                                                        handleDecrement()
                                                        }
                                                    }
                                            >
                                                <FontAwesomeIcon icon={faMinus}/>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="dash-widget-info">
                                <h6 className="text-muted">No. of Beds Available</h6>
                                <div className="progress progress-sm">
                                    <div className="progress-bar bg-warning w-100">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="dash-widget-header">
                                <span className="dash-widget-icon text-success">
                                    <FontAwesomeIcon icon={faUserInjured}/>
                                </span>
                                <div className="dash-count">
                                    <h3>{patientList && patientList.length ? patientList.length : 0}</h3>
                                </div>
                            </div>
                            <div className="dash-widget-info">
                                <h6 className="text-muted">No. of Patients Admitted</h6>
                                <div className="progress progress-sm">
                                    <div className="progress-bar bg-success w-100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="dash-widget-header">
                                <span className="dash-widget-icon text-primary border-primary">
                                    <FontAwesomeIcon icon={faUserMd}/>
                                </span>
                                <div className="dash-count">
                                    <h3>{doctorsList && doctorsList.length ? doctorsList.length : 0}</h3>
                                </div>
                            </div>
                            <div className="dash-widget-info">
                                <h6 className="text-muted">No. of Doctors</h6>
                                <div className="progress progress-sm">
                                    <div className="progress-bar bg-primary w-100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12">
                    <div className="card cursor-pointer">
                        <div className="card-body">
                            <div className="dash-widget-header" onClick={goToCheckin}>
                                <span className="dash-widget-icon text-danger border-danger">
                                    <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                </span>
                                <div className="dash-count">
                                </div>
                            </div>
                            <div className="dash-widget-info">

                                <h6 className="text-muted">Checkin</h6>
                                <div className="progress progress-sm">
                                    <div className="progress-bar bg-danger w-100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ClinicPatientList
                clinicId={clinicId}/>
        </>
    );
};

export default ClinicDashboard
