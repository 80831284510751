import firebase from 'firebase';
import '../config/firebase'
import {toast} from 'react-toastify';

const db = firebase.firestore();

export const useLogin = () => {
    const Auth = firebase.auth()

    const errorCallback = (error: any) => {
        toast.error('The user name or password is incorrect.')
    }
    const errorForgotPassword = (error: any) => {
        toast.error(error?.message)
    }

    const userLogin = (email: string, password: string, successCallBack: any) => {
        Auth.signInWithEmailAndPassword(email, password)
            .then((response) => {
                Auth.currentUser?.getIdTokenResult().then((idTokenResult) => {
                    console.log(idTokenResult.claims)
                    if (idTokenResult.claims.admin === true) {
                        return successCallBack(idTokenResult.claims);
                    }
                    return successCallBack(response.user)
                }).catch((error) => {
                    errorCallback(error)
                });
            })
            .catch(function (error) {
                errorCallback(error)
            });
    }
    const forgotPassword = (email: string, successCallBack: any) => {
        const actionCodeSettings = {
            url: process.env.REACT_APP_URL ||'https://my-opioid-recovery.web.app/',
            handleCodeInApp: false
        }
        Auth.sendPasswordResetEmail(email, actionCodeSettings)
            .then((response) => {
                successCallBack(response)
            })
            .catch(function (error) {
                errorForgotPassword(error)
            });
    }


    const userSignOut=()=>{
        sessionStorage.clear();
        Auth.signOut();
    }

    return {
        userLogin,
        userSignOut,
        errorCallback,
        forgotPassword
    }
}
