import * as React from 'react';
import Logo from '../assets/images/logo-icon.svg';
import AdminCreateClinic from "./admin/CreateClinic";

const Register = () => {
    return (
        <div className="login-wrapper">
            <div className="container">
                <div className="mb-4">
                    <img className="img-fluid" src={Logo} alt="Logo" width="50"/>
                </div>
                <AdminCreateClinic/>
            </div>
        </div>
    )
}

export default Register;
