import {FirestoreClient} from '../library/FirestoreClient'
import firebase from 'firebase';
import {TableNames} from "../helper/TableNames";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const db = firebase.firestore();

export const useClinic = () => {
    const Auth = firebase.auth()
    const navigate = useNavigate()

    const getClinicIdByUser = async (userid: string) => {
        const clinicId = await FirestoreClient.getRowByField(TableNames.Clinics, 'clinicEmail', userid).then((data) => {
            return data;
        }).catch(error => error)

        return clinicId;
    }
    //Copy the Generic questions to clinic table in case if they don't have default questions  added.
    const pushGenericQuestionsToClinicQuestions = async (clinicId: string) => {
        const questionsData = await FirestoreClient.getAll(TableNames.Questions).then((questionsCollection) => {

            questionsCollection.forEach((element: any) => {

                const modifiedObject = {...element, 'isPublished': false};
                delete modifiedObject["id"]

                FirestoreClient.saveSubCollection(TableNames.Clinics, clinicId, TableNames.ClinicQuestions, modifiedObject).then((result) => {

                })
            });
        })
    }

    const editClinic = (item: any) => {
        navigate("/admin/create-clinic", {state: item})
    }
    const viewClinic = (item: any) => {
        navigate("/admin/view-clinic/" + item.id)
    }

    const deleteClinic = (item: any) => {
        FirestoreClient.update(TableNames.Clinics, {isDeleted: true}, item['id']).then(() => {
            toast.success('Deleted successfully')

            // setTimeout(() => {
            //     window.location.href = "/admin/dashboard"

            // }, 1000)
        }).catch(err => err)
    }

    const approveClinic = (item: any) => {
        FirestoreClient.update(TableNames.Clinics, {isApproved: true}, item['id']).then(() => {
            const actionCodeSettings = {
                url: process.env.REACT_APP_URL || 'https://my-opioid-recovery.web.app/',
                handleCodeInApp: false
            }
            Auth.sendPasswordResetEmail(item.clinicEmail, actionCodeSettings)
            toast.success('Approved successfully')
            setTimeout(() => {
                window.location.href = "/admin/dashboard"

            }, 1000)
        }).catch(err => err)
    }

    const statusChangeClinic = (item: any) => {
        let activeStatus = !item.isActive
        FirestoreClient.update(TableNames.Clinics, {isActive: activeStatus}, item['id']).then(() => {
            toast.success('Status updated successfully')
            // setTimeout(() => {
            //     window.location.href = "/admin/dashboard"

            // }, 1000)
        }).catch(err => err)
    }

    const updateNoOfBeds = async (count: any, clinicId: string) => {
        const res = await FirestoreClient.update(TableNames.Clinics, {noOfBedsAvailable: count}, clinicId).then((response) => {
            return response
        }).catch((err) => {
            return err
        })
        return res
    }


    return {
        getClinicIdByUser,
        pushGenericQuestionsToClinicQuestions,
        editClinic,
        viewClinic,
        deleteClinic,
        statusChangeClinic,
        approveClinic,
        updateNoOfBeds

    }


}
