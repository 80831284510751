import {IFormBuilder} from "./FormBuilderType";

export interface ICustom {
    content: string,
    label: string
}

export interface IAddress {
    address1: string,
    address2: string,
    city: string,
    state: string,
    zip: number
}

export interface IAmenities {
    inventoryNumber: string,
    noOfBeds: string,
    customs: ICustom[],
}

export interface IClinic {
    id: string
    clinicName: string,
    clinicContact: string,
    clinicEmail: string,
    businessStatus: string,
    website: string,
    address?: IAddress,
    customs: ICustom[],
    createdOn?: firebase.firestore.Timestamp,
    noOfBedsAvailable?: string
    isActive?: boolean,
    isApproved?: boolean,
    isDeleted?: boolean,
    clinicQuestions?: IFormBuilder[]
    clinicAmenities?: IAmenities,
    other: string,
}

export class ClinicType implements IClinic {
    constructor(
        public id: string,
        public clinicName: string,
        public clinicContact: string,
        public clinicEmail: string,
        public businessStatus: string,
        public other: string,
        public website: string,
        public customs: ICustom[],
        public address: IAddress,
        public createdOn?: firebase.firestore.Timestamp,
        ) {
    }
    noOfBedsAvailable?: string | undefined
    isActive?: boolean | undefined;
    isApproved?: boolean | undefined;
    isDeleted?: boolean | undefined;
    clinicQuestions?: IFormBuilder[]
    clinicAmenities?: IAmenities

}

